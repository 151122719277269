
import React from "react";
import { Pagination as AntPagination } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './style.css'; // Ensure this file is included for custom styling

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const { t } = useTranslation(); // Use useTranslation

  return (
    <div className="pagination-container">
      <div className="pagination">
        <button
          className="prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {t('pagination.previous')}
        </button>
        <AntPagination
          current={currentPage}
          total={totalPages * 10} // Assuming 10 items per page
          pageSize={10}
          onChange={(page) => onPageChange(page)}
          showSizeChanger={false}
          showQuickJumper={false}
          hideOnSinglePage
        />
        <button
          className="next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {t('pagination.next')}
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;


// import React from "react";
// import { Pagination as AntPagination } from "antd";
// import PropTypes from "prop-types";
// import './style.css'; // Make sure you include this file for custom styling

// const Pagination = ({ currentPage, totalPages, onPageChange }) => {
//   return (
//     <div className="pagination-container">
//       <div className="pagination">
//         <button
//           className="prev"
//           onClick={() => onPageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <AntPagination
//           current={currentPage}
//           total={totalPages * 10} // Assuming 10 items per page
//           pageSize={10}
//           onChange={(page) => onPageChange(page)}
//           showSizeChanger={false}
//           showQuickJumper={false}
//           hideOnSinglePage
//         />
//         <button
//           className="next"
//           onClick={() => onPageChange(currentPage + 1)}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// Pagination.propTypes = {
//   currentPage: PropTypes.number.isRequired,
//   totalPages: PropTypes.number.isRequired,
//   onPageChange: PropTypes.func.isRequired,
// };

// export default Pagination;

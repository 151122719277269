// import React, { useEffect, useState, useRef } from 'react';
// import './style.css';
// import { stockWarehouseDetails } from "../modules/LandingPage";

// const Popup = ({ product_name, onClose }) => {
//     const [data, setData] = useState([]);
//     const popupRef = useRef(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const item_code = product_name.trim();
//                 const result = await stockWarehouseDetails(item_code);
//                 setData(result);
//             } catch (error) {
//                 console.error("Error fetching data", error);
//             }
//         };
//         fetchData();
//     }, [product_name]);

//     // Handle closing the popup when clicking outside of it
//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (popupRef.current && !popupRef.current.contains(event.target)) {
//                 onClose();
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, [onClose]);

//     const currentWarehouse = localStorage.getItem('costCenter');

//     return (
//         <div className="unique-popup-container">
//             <div className="unique-popup-content" ref={popupRef}>
//                 <p className="unique-popup-subtitle">{product_name}</p>
//                 <div className="unique-product-info">
//                     <p className="unique-product-quantity">
//                         Product Quantity : <span className="unique-quantity-value">0</span>
//                     </p>
//                     <p className="unique-warehouse-name">
//                         Warehouse Name : <span className="unique-warehouse-value">{currentWarehouse}</span>
//                     </p>
//                 </div>
//                 <table className="unique-warehouse-table">
//                     <thead>
//                         <tr>
//                             <th>Name</th>
//                             <th>Warehouse Address</th>
//                             <th>Warehouse Location</th>
//                             <th>Quantity</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data && data.message && data.item_warehouse_list && (
//                             data.item_warehouse_list.map((item, index) => (
//                                 <tr key={index}>
//                                     <td>{item.name}</td>
//                                     <td>{item.custom_address}</td>
//                                     <td>{item.custom_location}</td>
//                                     <td>{item.qty_after_transaction}</td>
//                                 </tr>
//                             ))
//                         )}
//                     </tbody>
//                 </table>
//                 <button className="unique-close-button" onClick={onClose}>Close</button>
//             </div>
//         </div>
//     );
// }

// export default Popup;

import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';
import { stockWarehouseDetails } from "../modules/LandingPage";

const Popup = ({ product_name, onClose }) => {
    const [data, setData] = useState([]);
    const popupRef = useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const item_code = product_name.trim();
                const result = await stockWarehouseDetails(item_code);
                setData(result);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };
        fetchData();
    }, [product_name]);

    // Handle closing the popup when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const currentWarehouse = localStorage.getItem('costCenter');

    return (
        <div className="unique-popup-container">
            <div className="unique-popup-content" ref={popupRef}>
                <p className="unique-popup-subtitle">{product_name}</p>
                <div className="unique-product-info">
                    <p className="unique-product-quantity">
                        {t('StockPopUp.product_quantity')} : <span className="unique-quantity-value">0</span>
                    </p>
                    <p className="unique-warehouse-name">
                        {t('StockPopUp.warehouse_name')} : <span className="unique-warehouse-value">{currentWarehouse}</span>
                    </p>
                </div>
                <table className="unique-warehouse-table">
                    <thead>
                        <tr>
                            <th>{t('StockPopUp.name')}</th>
                            <th>{t('StockPopUp.warehouse_address')}</th>
                            <th>{t('StockPopUp.warehouse_location')}</th>
                            <th>{t('StockPopUp.quantity')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.message && data.item_warehouse_list && (
                            data.item_warehouse_list.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.custom_address}</td>
                                    <td>{item.custom_location}</td>
                                    <td>{item.qty_after_transaction}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <button className="unique-close-button" onClick={onClose}>{t('StockPopUp.close')}</button>
            </div>
        </div>
    );
}

export default Popup;


import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";
import { getCouponCodeList } from "../modules/LandingPage";
import { useTranslation } from 'react-i18next';

const PromoCodePopup = ({ open, onClose, onApply, validatedPromoCode }) => {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState("");
  const [couponCodes, setCouponCodes] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track expanded text index

  useEffect(() => {
    const fetchCouponCodes = async () => {
      const response = await getCouponCodeList();
      if (response.message && response.message.status === "success") {
        setCouponCodes(response.message.valid_coupons);
      }
    };

    if (open) {
      fetchCouponCodes();
      setPromoCode(""); // Clear the input field when the popup opens
    }
  }, [open]);

  const handleApplyClick = (code) => {
    onApply(code || promoCode);
    setPromoCode("");
    onClose(); // Close the modal after applying the promo code
  };

  const handleCouponCodeClick = (code) => {
    handleApplyClick(code);
  };

  const getCouponCodeClass = (text) => {
    if (text.length > 15) return "coupon-code-large";
    if (text.length > 8) return "coupon-code-medium";
    return "coupon-code-small";
  };

  const toggleTextExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const renderText = (text, index, wordLimit) => {
    if (!text) return ""; // Return an empty string if text is null or undefined

    const isExpanded = expandedIndex === index;
    const words = text.split(" ");
    const truncatedText = words.slice(0, wordLimit).join(" ");
    const displayText = isExpanded || words.length <= wordLimit ? text : truncatedText + "...";

    return (
      <span onClick={() => toggleTextExpansion(index)} style={{ cursor: "pointer" }}>
        {displayText} {words.length > wordLimit && (isExpanded ? t('PromoCodePopUp.collapse') : t('PromoCodePopUp.expand_more'))}
      </span>
    );
  };

  useEffect(() => {
    if (validatedPromoCode) {
      setPromoCode(validatedPromoCode);
    }
  }, [validatedPromoCode]);

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      footer={null}
      className="promo-code-popup"
    >
      <div className="promo-code-box">
        <div className="promo-code-header">
          <div className="promoCodeContainer">
            <Input
              placeholder={t('PromoCodePopUp.promo_code')}
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="promo-input"
            />
            <Button type="default" onClick={() => handleApplyClick()}>
              {t('PromoCodePopUp.apply')}
            </Button>
          </div>
        </div>
        <div className="couponCodesList">
          {couponCodes.length > 0 ? (
            <ul>
              {couponCodes.map((code, index) => (
                <li key={index} className="coupon-code-box">
                  <div className="coupon-left">
                    <span className={getCouponCodeClass(code.coupon_code)}>
                      {code.coupon_code}
                    </span>
                  </div>
                  <div className="coupon-right">
                    <div className="code-apply">
                      <span>{code.coupon_code}</span>
                      <span
                        className="apply-btn promo-code-button"
                        onClick={() => handleCouponCodeClick(code.coupon_code)}
                      >
                        {t('PromoCodePopUp.apply_btn')}
                      </span>
                    </div>
                    <div className="code-desc">
                      {renderText(code.description, index, 12)} {/* Adjust word limit */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('PromoCodePopUp.no_coupons')}</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PromoCodePopup;
// import React, { useState, useEffect } from "react";
// import { Modal, Button, Input } from "antd";
// import { getCouponCodeList } from "../modules/LandingPage";

// const PromoCodePopup = ({ open, onClose, onApply, validatedPromoCode }) => {
//   const [promoCode, setPromoCode] = useState("");
//   const [couponCodes, setCouponCodes] = useState([]);
//   const [expandedIndex, setExpandedIndex] = useState(null); // Track expanded text index

//   useEffect(() => {
//     const fetchCouponCodes = async () => {
//       const response = await getCouponCodeList();

//       if (response.message && response.message.status === "success") {
//         setCouponCodes(response.message.valid_coupons);
//       }
//     };

//     if (open) {
//       fetchCouponCodes();
//       setPromoCode(""); // Clear the input field when the popup opens
//     }
//   }, [open]);

//   const handleApplyClick = (code) => {
//     onApply(code || promoCode);
//     setPromoCode("");
//     onClose(); // Close the modal after applying the promo code
//   };

//   const handleCouponCodeClick = (code) => {
//     handleApplyClick(code);
//   };

//   const getCouponCodeClass = (text) => {
//     if (text.length > 15) return "coupon-code-large";
//     if (text.length > 8) return "coupon-code-medium";
//     return "coupon-code-small";
//   };

//   const toggleTextExpansion = (index) => {
//     setExpandedIndex(expandedIndex === index ? null : index);
//   };

//   const renderText = (text, index, wordLimit) => {
//     if (!text) return ""; // Return an empty string if text is null or undefined

//     const isExpanded = expandedIndex === index;
//     const words = text.split(" ");
//     const truncatedText = words.slice(0, wordLimit).join(" ");
//     const displayText = isExpanded || words.length <= wordLimit ? text : truncatedText + "...";

//     return (
//       <span onClick={() => toggleTextExpansion(index)} style={{ cursor: "pointer" }}>
//         {displayText}
//       </span>
//     );
//   };

//   useEffect(() => {
//     if (validatedPromoCode) {
//       setPromoCode(validatedPromoCode);
//     }
//   }, [validatedPromoCode]);

//   return (
//     <Modal
//       visible={open}
//       onCancel={onClose}
//       footer={null}
//       className="promo-code-popup"
//     >
//       <div className="promo-code-box">
//         <div className="promo-code-header">
//           <div className="promoCodeContainer">
//             <Input
//               placeholder="Enter your promo code"
//               value={promoCode}
//               onChange={(e) => setPromoCode(e.target.value)}
//               className="promo-input"
//             />
//             <Button type="default" onClick={() => handleApplyClick()}>
//               Apply
//             </Button>
//           </div>
//         </div>
//         <div className="couponCodesList">
//           {couponCodes.length > 0 ? (
//             <ul>
//               {couponCodes.map((code, index) => (
//                 <li key={index} className="coupon-code-box">
//                   <div className="coupon-left">
//                     <span className={getCouponCodeClass(code.coupon_code)}>
//                       {code.coupon_code}
//                     </span>
//                   </div>
//                   <div className="coupon-right">
//                     <div className="code-apply">
//                       <span>{code.coupon_code}</span>
//                       <span
//                         className="apply-btn promo-code-button"
//                         onClick={() => handleCouponCodeClick(code.coupon_code)}
//                       >
//                         Apply
//                       </span>
//                     </div>
//                     <div className="code-desc">
//                       {renderText(code.description, index, 12)} {/* Adjust word limit */}
//                     </div>
//                   </div>
//                 </li>
//               ))}
//             </ul>
//           ) : (
//             <p>No valid coupons available.</p>
//           )}
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default PromoCodePopup;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import Layout from "./Layout";
// import { fetchstore,getLocation } from "../modules/LandingPage";


// const Location = () => {
//   const [location, setLocation] = useState([]);
//   const [selectedLocation, setSelectedLocation] = useState("");
//   const [customLocationData, setCustomLocationData] = useState([]);
//   const [selectedCostCenter,setSelectedCostCenter ] = useState("")
//   const navigate = useNavigate();
//   const locationState = useLocation();
//   const loginResponse = locationState.state?.loginResponse;


//   const Locations = async () => {
//     try {
//       const data = await getLocation();
//       if (data.status === 200) {
//         setLocation(data.data.message || [])
//       } else {
//         alert("Login failed. Please check your credentials.");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       alert("An error occurred during login. Please try again.");
//     }
//   };

//   useEffect(() =>{
//     Locations();
//   },[])

//   const fetchStore = async(selected) =>{
//     try {
//         const res = await fetchstore(selected);
//         if (res.status === 200) {
//           setCustomLocationData(res.data.message || [])
//         } else {
//           console.log(". Please check  API.");
//         }
//       } catch (error) {
//         console.error("Fetching store error:", error);
//         // alert("An error occurred during get store. Please try again.");
//       }
//     };

//   const handleLocationChange = (e) => {
//     const selected = e.target.value;
//     setSelectedLocation(selected);
//     fetchStore(selected);
//   };

//   const handleCostCenterChange = (e) => {
//     const selected = e.target.value;
//     setSelectedCostCenter(selected);
//     localStorage.setItem("costCenter",selected)
//   };

  
//   const handleProceed = () => {
//     if (selectedLocation && selectedCostCenter) {
//         navigate("/openshift", { state: { loginResponse } });
//     } else {
//       alert("Please select both location and store before proceeding.");
//     }
//   };


//   return (
//     <div className="login-page">
//       <Layout showFooter={false} showDropdown={false}>
//         <div className="login-screen">
//           <form className="login-form">
//             <div className="form-group">
//             <select
//                 id="location"
//                 value={selectedLocation}
//                 onChange={handleLocationChange}
//               >
//                 <option value="">Select Location</option>
//                 {location.map((loc, index) => (
//                   <option key={index} value={loc.custom_location}>
//                     {loc.custom_location}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-group">
//             <select
//                 id="location"
//                 value={selectedCostCenter}
//                 onChange={handleCostCenterChange}
//               >
//                 <option value="">Select Store</option>
//                 {customLocationData.map((loc, index) => (
//                   <option key={index} value={loc.name}>
//                     {loc.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <button type="button" onClick={handleProceed}>
//               Submit
//             </button>
//           </form>
//         </div>
//       </Layout>
//     </div>
//   );
// };

// export default Location;

// src/components/Location.js
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "./Layout";
import { fetchstore, getLocation } from "../modules/LandingPage";
import { useTranslation } from 'react-i18next';

const Location = () => {
  const [location, setLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [customLocationData, setCustomLocationData] = useState([]);
  const [selectedCostCenter, setSelectedCostCenter] = useState("");
  const navigate = useNavigate();
  const locationState = useLocation();
  const loginResponse = locationState.state?.loginResponse;
  const { t } = useTranslation(); // Use the translation hook

  const Locations = async () => {
    try {
      const data = await getLocation();
      if (data.status === 200) {
        setLocation(data.data.message || []);
      } else {
        alert(t("Location.selectBoth")); // Use translation
      }
    } catch (error) {
      console.error("Login error:", error);
      alert(t("Location.selectBoth")); // Use translation
    }
  };

  useEffect(() => {
    Locations();
  }, []);

  const fetchStore = async (selected) => {
    try {
      const res = await fetchstore(selected);
      if (res.status === 200) {
        setCustomLocationData(res.data.message || []);
      } else {
        console.log(". Please check API.");
      }
    } catch (error) {
      console.error("Fetching store error:", error);
    }
  };

  const handleLocationChange = (e) => {
    const selected = e.target.value;
    setSelectedLocation(selected);
    fetchStore(selected);
  };

  const handleCostCenterChange = (e) => {
    const selected = e.target.value;
    setSelectedCostCenter(selected);
    localStorage.setItem("costCenter", selected);
  };

  const handleProceed = () => {
    if (selectedLocation && selectedCostCenter) {
      navigate("/openshift", { state: { loginResponse } });
    } else {
      alert(t("Location.selectBoth")); // Use translation
    }
  };

  return (
    <div className="login-page">
      <Layout showFooter={false} showDropdown={false}>
        <div className="login-screen">
          <form className="login-form">
            <div className="form-group">
              <select
                id="location"
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                <option value="">{t("Location.selectLocation")}</option> {/* Use translation */}
                {location.map((loc, index) => (
                  <option key={index} value={loc.custom_location}>
                    {loc.custom_location}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <select
                id="cost-center"
                value={selectedCostCenter}
                onChange={handleCostCenterChange}
              >
                <option value="">{t("Location.selectStore")}</option> {/* Use translation */}
                {customLocationData.map((loc, index) => (
                  <option key={index} value={loc.name}>
                    {loc.name}
                  </option>
                ))}
              </select>
            </div>
            <button type="button" onClick={handleProceed}>
              {t("Location.submit")} {/* Use translation */}
            </button>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Location;

// import React, { useContext, useEffect, useState } from "react";
// import "./style.css";
// import Logo from "../assets/images/steves-favicon-red.png";
// import SearchIcon from "../assets/images/Search-icon.png";
// import { useLocation } from 'react-router-dom';

// const Header = ({ onSearch }) => {
//   const [user, setUser] = useState(null);
//   const [menuOpen, setMenuOpen] = useState(false);
//   const location = useLocation();
//   const [searchQuery, setSearchQuery] = useState("");


//   useEffect(() => {
//     const userData = localStorage.getItem("user");
//     if (userData) {
//       setUser(JSON.parse(userData));
//     } else {
//       console.log("No user data found in localStorage");
//     }
//   }, []);

//   const handleSearch = (e) => {
//     setSearchQuery(e.target.value);
//     onSearch(e.target.value);
//   };

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   return (
//     <header className="header">
//       <div className="header-logo">
//         {user ? (
//           <a href="/main">
//             <img src={Logo} alt="Logo" />
//           </a>
//         ) : (
//           <img src={Logo} alt="Logo" />
//         )}
//       </div>
//       {user && location.pathname === '/main' ? (
//         <>
//           <div className="search-container">
//             <input
//               type="text"
//               placeholder="Search"
//               value={searchQuery}
//               onChange={handleSearch}
//             />
//             <img src={SearchIcon} alt=""/>
//           </div>
//           {/* <div className="header-user-profile">
//             <img
//               src={user.profileImage || PlaceholderProfile}
//               alt="Profile"
//               className="profile-image"
//             />
//             <span className="header-user-name">
//               {user.name} <br /> {user.role}
//             </span>
//             <div className="header-user-dropdown">
//               <button className="dropdown-button">
//                 <img src={Arrow} alt="" />
//               </button>
//               <div className="dropdown-content">
//                 <a href="/profile">Profile</a>
//                 <a href="/closeshift">Logout</a>
//               </div>
//             </div>
//           </div> */}
//         </>
//       ) : (
//         []
//       )}
//       <div
//         className={`burger-menu ${menuOpen ? "open" : ""}`}
//         onClick={toggleMenu}
//       >
//         <div className="burger-bar"></div>
//         <div className="burger-bar"></div>
//         <div className="burger-bar"></div>
//       </div>
//       <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
//         {user ? (
//           <>
//             <a href="/profile">Profile</a>
//             <a href="/closeshift">Logout</a>
//           </>
//         ) : (
//           <a href="/">Login</a>
//         )}
//       </div>
//     </header>
//   );
// };

// export default Header;

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./style.css";
import Logo from "../assets/images/steves-favicon-red.png";
import SearchIcon from "../assets/images/Search-icon.png";
import { useLocation } from 'react-router-dom';

const Header = ({ onSearch }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUser(JSON.parse(userData));
    } else {
      console.log("No user data found in localStorage");
    }
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header-logo">
        {user ? (
          <a href="/main">
            <img src={Logo} alt={t('Header.logoAltText')} />
          </a>
        ) : (
          <img src={Logo} alt={t('Header.logoAltText')} />
        )}
      </div>
      {user && location.pathname === '/main' ? (
        <>
          <div className="search-container">
            <input
              type="text"
              placeholder={t('Header.searchPlaceholder')}
              value={searchQuery}
              onChange={handleSearch}
            />
            <img src={SearchIcon} alt={t('Header.searchIconAltText')} />
          </div>
          {/* Uncomment and add functionality for user profile section if needed */}
          {/* <div className="header-user-profile">
            <img
              src={user.profileImage || PlaceholderProfile}
              alt={t('profileImageAltText')}
              className="profile-image"
            />
            <span className="header-user-name">
              {user.name} <br /> {user.role}
            </span>
            <div className="header-user-dropdown">
              <button className="dropdown-button">
                <img src={Arrow} alt={t('dropdownArrowAltText')} />
              </button>
              <div className="dropdown-content">
                <a href="/profile">{t('profile')}</a>
                <a href="/closeshift">{t('logout')}</a>
              </div>
            </div>
          </div> */}
        </>
      ) : null}
      <div
        className={`burger-menu ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
        <div className="burger-bar"></div>
      </div>
      <div className={`mobile-menu ${menuOpen ? "open" : ""}`}>
        {user ? (
          <>
            <a href="/profile">{t('Header.profile')}</a>
            <a href="/closeshift">{t('Header.logout')}</a>
          </>
        ) : (
          <a href="/">{t('Header.login')}</a>
        )}
      </div>
    </header>
  );
};

export default Header;

import React, { useState, useEffect, useContext, useRef } from "react";
import Sidebar from "./Sidebar";
import ProductCatalog from "./ProductCatalog";
import Cart from "./Cart";
import Header from "./Header";
import Footer from "./Footer";
import { fetchCategoriesAndProducts, getItemByScan } from "../modules/LandingPage";
import { Button, Spin, Modal, notification } from "antd";
import { ShoppingCartOutlined, DoubleRightOutlined } from "@ant-design/icons";
import useIsSmallScreen from "../hooks/useIsSmallScreen";
import { CartContext } from "../common/CartContext";
import ProductPopup from './ProductPopup';

const MainScreen = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [popupProduct, setPopupProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [barcode, setBarcode] = useState("");
  const lastKeyPressTime = useRef(0);

  const isSmallScreen = useIsSmallScreen();
  const costCenter = localStorage.getItem("costCenter");

  const { addItemToCart } = useContext(CartContext);
  const searchInputRef = useRef(null);
  const scanTimeout = useRef(null);
  const [NotFound,setNotFound]=useState(false);

  const getSelectedCustomer = () => {
    const customer = localStorage.getItem("selectedCustomer");
    return customer ? JSON.parse(customer) : null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCategoriesAndProducts(costCenter);
        setCategories(data);
        if (data.length > 0) {
          setSelectedCategory(data[0].item_group);
          const allProducts = data.flatMap((category) =>
            category.items.map((item) => ({
              ...item,
              category: category.item_group,
            }))
          );
          setProducts(allProducts);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [costCenter]);

  console.log("Categories ", categories);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      const results = products.filter(
        (product) =>
          product.name.toLowerCase().includes(query.toLowerCase()) ||
          product.category.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(results);
      if(results.length===0){
        setNotFound(true)
      }else{
        setNotFound(false)
      }
    } else {
      setSearchResults([]);
    }
  };

  // const handleKeyDown = (e) => {
  //   const activeElement = document.activeElement;
  //   const currentTime = new Date().getTime();
  
  //   if (activeElement !== searchInputRef.current) {
  //     const isScan = currentTime - lastKeyPressTime.current < 50;
  //     lastKeyPressTime.current = currentTime;
  
  //     if (e.keyCode >= 48 && e.keyCode <= 90) {
  //       setBarcode((prev) => prev + e.key);
  //     }
  //     if (e.keyCode === 13 && barcode.length > 3 && isScan) {
  //       clearTimeout(scanTimeout.current);
  //       scanTimeout.current = setTimeout(() => {
  //         console.log("Scanned Barcode:", barcode);
  //         handleScan(barcode);
  //         setBarcode(""); 
  //       }, 500); // Adjust debounce time as needed
  //     }
  //   }
  // };  

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);

  //   return function cleanup() {
  //     document.removeEventListener("keydown", handleKeyDown);
  //     clearTimeout(scanTimeout.current);
  //   };
  // }, [barcode]);
  const handleKeyDown = (e) => {
    const activeElement = document.activeElement;
    const currentTime = new Date().getTime();
  
    if (activeElement !== searchInputRef.current) {
      const isScan = currentTime - lastKeyPressTime.current < 50;
      lastKeyPressTime.current = currentTime;
  
      if ((e.keyCode >= 48 && e.keyCode <= 90) || e.key === '-' || e.key === '_' || e.key === '.') {
        setBarcode((prev) => prev + e.key);
      }
  
      if (e.keyCode === 13 && barcode.length > 3 && isScan) {
        clearTimeout(scanTimeout.current);
        scanTimeout.current = setTimeout(() => {
          console.log("Scanned Barcode:", barcode);
          handleScan(barcode);
          setBarcode(""); // Clear barcode after processing
        }, 500);
      }
    }
  };
  
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
  
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      clearTimeout(scanTimeout.current);
    };
  }, [barcode]);
  const formatBarcode = (barcode) => {
    
    barcode = barcode.trim().replace(/\s+/g, "");
  
    const patterns = [
      { type: "CODE 39", regex: /^[A-Z0-9\-$/+%.]+$/ }, // CODE 39: Alphanumeric with special characters
      { type: "EAN-8", regex: /^\d{8}$/ }, // EAN-8: 8-digit numeric code
      { type: "EAN-13", regex: /^\d{13}$/ }, // EAN-13: 13-digit numeric code
      { type: "UPC-A", regex: /^\d{12}$/ }, // UPC-A: 12-digit numeric code
      { type: "UPC-E", regex: /^\d{6,8}$/ }, // UPC-E: 6 or 8-digit numeric code
      { type: "ISBN 13", regex: /^(978|979)\d{10}$/ }, // ISBN 13: 13-digit starting with 978 or 979
      { type: "CODE 128", regex: /^[\x00-\x7F]+$/ }, // CODE 128: Supports all ASCII characters
      { type: "QR CODE", regex: /^[\x00-\x7F]+$/ }, // QR CODE: Supports various lengths and characters
      { type: "PZN 8", regex: /^\d{8}$/ }, // PZN 8: 8-digit code used for pharmaceuticals
      // Add more patterns as needed for other barcodes
    ];
  
    const matchedPattern = patterns.find((pattern) => pattern.regex.test(barcode));
  
    if (matchedPattern) {
      switch (matchedPattern.type) {
        case "ISBN 13":
          // Optional formatting for ISBN
          return `${barcode.slice(0, 3)}-${barcode.slice(3)}`;
        case "PZN 8":
          // Optional formatting for PZN
          return `PZN-${barcode}`;
        default:
          return barcode; // Return as is for other types
      }
    }
 
    return barcode;
  };


  // const sanitizeBarcode = (barcode) => {
  //   return barcode.replace(/\D/g, '');
  // };

  const handleScan = async (barCodeString) => {
    const formattedBarcode = formatBarcode(barCodeString);
    // const sanitizedBarcode = sanitizeBarcode(barCodeString);
    const selectedCustomer = getSelectedCustomer();

    if (!selectedCustomer) {
      Modal.error({
        title: "Attention!",
        content: "Please select a customer before adding items to the cart.",
      });
      return;
    }

    try {
      const res = await getItemByScan(formattedBarcode, costCenter);
      if (res.status === 200) {
        const items = res.data.message[0].items;
        items.forEach((item) => {
          if (item.stock_qty > 0) {
            addItemToCart({
              ...item,
              quantity: 1,
              isScanned: true,
            });
          } else {
            Modal.warning({
              title: "Item Out of Stock",
              content: `The item "${item.name}" is out of stock.`,
            });
          }
        });
      } else {
        Modal.warning({
          title: "Item Not Found",
          content: "The scanned item was not found.",
        });
      }
    } catch (error) {
      if(error instanceof TypeError){
        Modal.warning({
          title: "Item Not Found",
        });
      } else{
        Modal.error({
          title: "Scan Error",
          content: "There was an error scanning the item. Please try again.",
        });
      }
    }
  };
  

  if (loading) {
    return (
      <div className="loading-spin">
        <Spin tip="Loading..."></Spin>
      </div>
    );
  }

  const handleAddToCart = (product) => {
    setPopupProduct(product);
  };

  const displayedProducts =
    searchResults.length > 0
      ? searchResults
      : NotFound ? [] : products.filter((product) => product.category === selectedCategory);

  const highlightedCategory =
    searchResults.length > 0 ? searchResults[0]?.category : selectedCategory;

  return (
    <>
      <div className="cart-page-layout">
        <div className="main-screen">
          <Header onSearch={handleSearch} searchQuery={searchQuery} />
          {isSmallScreen && (
            <Button
              className="menu-button"
              onClick={() => setIsSidebarVisible(true)}
              icon={<DoubleRightOutlined />}
            />
          )}
          <div className="left-cont">
            <Sidebar
              categories={categories}
              onSelectCategory={setSelectedCategory}
              selectedCategory={highlightedCategory}
              isVisible={isSidebarVisible}
              onClose={() => setIsSidebarVisible(false)}
              isSmallScreen={isSmallScreen}
            />
            <ProductCatalog
              categoryName={highlightedCategory}
              products={displayedProducts}
              onAddToCart={handleAddToCart}
            />
          </div>
          <Footer />
        </div>
        <div className={`cart-container ${isCartVisible ? "cart-visible" : ""}`}>
          <Cart />
        </div>
        <Button
          className="cart-button"
          onClick={() => setIsCartVisible(!isCartVisible)}
          icon={<ShoppingCartOutlined />}
        />
        {popupProduct && (
          <ProductPopup
            product={popupProduct}
            onAddItem={(item) => {
              addItemToCart(item);
              setPopupProduct(null);
            }}
            onClose={() => setPopupProduct(null)}
          />
        )}
      </div>
    </>
  );
};

export default MainScreen;


// import React, { useState, useEffect, useContext, useRef } from "react";
// import Sidebar from "./Sidebar";
// import ProductCatalog from "./ProductCatalog";
// import Cart from "./Cart";
// import Header from "./Header";
// import Footer from "./Footer";
// import { fetchCategoriesAndProducts, getItemByScan } from "../modules/LandingPage";
// import { Button, Spin, Modal, notification } from "antd";
// import { ShoppingCartOutlined, DoubleRightOutlined } from "@ant-design/icons";
// import useIsSmallScreen from "../hooks/useIsSmallScreen";
// import { CartContext } from "../common/CartContext";
// import ProductPopup from './ProductPopup';

// const MainScreen = () => {
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [popupProduct, setPopupProduct] = useState(null);
//   const [categories, setCategories] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [searchResults, setSearchResults] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isCartVisible, setIsCartVisible] = useState(false);
//   const [isSidebarVisible, setIsSidebarVisible] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [barcode, setBarcode] = useState("");
//   const lastKeyPressTime = useRef(0);

//   const isSmallScreen = useIsSmallScreen();
//   const costCenter = localStorage.getItem("costCenter");

//   const { addItemToCart } = useContext(CartContext);
//   const searchInputRef = useRef(null);
//   const scanTimeout = useRef(null);
//   const [NotFound,setNotFound]=useState(false);

//   const getSelectedCustomer = () => {
//     const customer = localStorage.getItem("selectedCustomer");
//     return customer ? JSON.parse(customer) : null;
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await fetchCategoriesAndProducts(costCenter);
//         setCategories(data);
//         if (data.length > 0) {
//           setSelectedCategory(data[0].item_group);
//           const allProducts = data.flatMap((category) =>
//             category.items.map((item) => ({
//               ...item,
//               category: category.item_group,
//             }))
//           );
//           setProducts(allProducts);
//         }
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data", error);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [costCenter]);

//   const handleSearch = (query) => {
//     setSearchQuery(query);
//     if (query) {
//       const results = products.filter(
//         (product) =>
//           product.name.toLowerCase().includes(query.toLowerCase()) ||
//           product.category.toLowerCase().includes(query.toLowerCase())
//       );
//       setSearchResults(results);
//       if(results.length===0){
//         setNotFound(true)
//       }else{
//         setNotFound(false)
//       }
//     } else {
//       setSearchResults([]);
//     }
//   };

//   const handleKeyDown = (e) => {
//     const activeElement = document.activeElement;
//     const currentTime = new Date().getTime();

//     if (activeElement !== searchInputRef.current) {
//       const isScan = currentTime - lastKeyPressTime.current < 50;
//       lastKeyPressTime.current = currentTime;

//       if (e.keyCode >= 48 && e.keyCode <= 90) {
//         setBarcode((prev) => prev + e.key);
//       }
//       if (e.keyCode === 13 && barcode.length > 3 && isScan) {
//         clearTimeout(scanTimeout.current);
//         scanTimeout.current = setTimeout(() => {
//           handleScan(barcode);
//           setBarcode("");
//         }, 500); // Adjust debounce time as needed
//       }
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("keydown", handleKeyDown);

//     return function cleanup() {
//       document.removeEventListener("keydown", handleKeyDown);
//       clearTimeout(scanTimeout.current);
//     };
//   }, [barcode]);

//   const handleScan = async (barCodeString) => {
//     const selectedCustomer = getSelectedCustomer();

//     if (!selectedCustomer) {
//       Modal.error({
//         title: "Attention!",
//         content: "Please select a customer before adding items to the cart.",
//       });
//       return;
//     }

//     try {
//       const res = await getItemByScan(barCodeString, costCenter);
//       if (res.status === 200) {
//         const items = res.data.message[0].items;
//         items.forEach((item) => {
//           if (item.stock_qty > 0) {
//             addItemToCart({
//               ...item,
//               quantity: 1,
//               isScanned: true,
//             });
//           } else {
//             Modal.warning({
//               title: "Item Out of Stock",
//               content: `The item "${item.name}" is out of stock.`,
//             });
//           }
//         });
//       } else {
//         Modal.warning({
//           title: "Item Not Found",
//           content: "The scanned item was not found.",
//         });
//       }
//     } catch (error) {
//       if(error instanceof TypeError){
//           Modal.warning({
//             title: "Scan Error",
//             content: `Please retry again`,
//           });
//       }else{
//         Modal.error({
//           title: "Scan Error",
//           content: "There was an error scanning the item. Please try again.",
//         });
//       }
//     }
//   };
  

//   if (loading) {
//     return (
//       <div className="loading-spin">
//         <Spin tip="Loading..."></Spin>
//       </div>
//     );
//   }

//   const handleAddToCart = (product) => {
//     setPopupProduct(product);
//   };

//   const displayedProducts =
//     searchResults.length > 0
//       ? searchResults
//       : NotFound ? [] : products.filter((product) => product.category === selectedCategory);

//   const highlightedCategory =
//     searchResults.length > 0 ? searchResults[0]?.category : selectedCategory;

//   return (
//     <>
//       <div className="cart-page-layout">
//         <div className="main-screen">
//           <Header onSearch={handleSearch} searchQuery={searchQuery} />
//           {isSmallScreen && (
//             <Button
//               className="menu-button"
//               onClick={() => setIsSidebarVisible(true)}
//               icon={<DoubleRightOutlined />}
//             />
//           )}
//           <div className="left-cont">
//             <Sidebar
//               categories={categories}
//               onSelectCategory={setSelectedCategory}
//               selectedCategory={highlightedCategory}
//               isVisible={isSidebarVisible}
//               onClose={() => setIsSidebarVisible(false)}
//               isSmallScreen={isSmallScreen}
//             />
//             <ProductCatalog
//               categoryName={highlightedCategory}
//               products={displayedProducts}
//               onAddToCart={handleAddToCart}
//             />
//           </div>
//           <Footer />
//         </div>
//         <div className={`cart-container ${isCartVisible ? "cart-visible" : ""}`}>
//           <Cart />
//         </div>
//         <Button
//           className="cart-button"
//           onClick={() => setIsCartVisible(!isCartVisible)}
//           icon={<ShoppingCartOutlined />}
//         />
//         {popupProduct && (
//           <ProductPopup
//             product={popupProduct}
//             onAddItem={(item) => {
//               addItemToCart(item);
//               setPopupProduct(null);
//             }}
//             onClose={() => setPopupProduct(null)}
//           />
//         )}
//       </div>
//     </>
//   );
// };

// export default MainScreen;

// // import React, { useState, useEffect } from "react";
// // import Sidebar from "./Sidebar";
// // import ProductCatalog from "./ProductCatalog";
// // import Cart from "./Cart";
// // import ProductPopup from "./ProductPopup";
// // import Header from "./Header";
// // import Footer from "./Footer";
// // import { fetchCategoriesAndProducts } from "../modules/LandingPage";
// // import { Button, Spin } from "antd";
// // import { ShoppingCartOutlined, DoubleRightOutlined } from "@ant-design/icons";
// // import useIsSmallScreen from "../hooks/useIsSmallScreen";
// // // Import ProductPopup from './ProductPopup'

// // const MainScreen = () => {
// //   const [selectedCategory, setSelectedCategory] = useState(null);
// //   const [cartItems, setCartItems] = useState([]);
// //   const [popupProduct, setPopupProduct] = useState(null);
// //   const [categories, setCategories] = useState([]);
// //   const [products, setProducts] = useState([]);
// //   const [searchResults, setSearchResults] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [isCartVisible, setIsCartVisible] = useState(false);
// //   const [isSidebarVisible, setIsSidebarVisible] = useState(false);

// //   const isSmallScreen = useIsSmallScreen();

// //   const costCenter = localStorage.getItem("costCenter")

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const data = await fetchCategoriesAndProducts(costCenter);
// //         setCategories(data);
// //         if (data.length > 0) {
// //           setSelectedCategory(data[0].item_group);
// //           const allProducts = data.flatMap((category) =>
// //             category.items.map((item) => ({
// //               ...item,
// //               category: category.item_group,
// //             }))
// //           );
// //           setProducts(allProducts);
// //         }
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data", error);
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, []);

// //   useEffect(() => {
// //     localStorage.setItem("cartItems", JSON.stringify(cartItems));
// //   }, [cartItems]);

// //   const handleAddToCart = (product) => {
// //     setPopupProduct(product);
// //   };

// //   const handleAddItem = (item) => {
// //     const existingItemIndex = cartItems.findIndex(
// //       (cartItem) => cartItem.id === item.id
// //     );
// //     if (existingItemIndex >= 0) {
// //       const updatedCartItems = [...cartItems];
// //       updatedCartItems[existingItemIndex].quantity += item.quantity;
// //       setCartItems(updatedCartItems);
// //     } else {
// //       setCartItems([...cartItems, item]);
// //     }
// //   };

// //   const handleUpdateCartItem = (updatedItem) => {
// //     const updatedCartItems = cartItems.map((item) =>
// //       item.id === updatedItem.id ? updatedItem : item
// //     );
// //     setCartItems(updatedCartItems);
// //   };

// //   const handleRemoveFromCart = (index) => {
// //     setCartItems(cartItems.filter((_, i) => i !== index));
// //   };

// //   const handleSearch = (query) => {
// //     if (query) {
// //       const results = products.filter(
// //         (product) =>
// //           product.name.toLowerCase().includes(query.toLowerCase()) ||
// //           product.category.toLowerCase().includes(query.toLowerCase())
// //       );
// //       setSearchResults(results);
// //     } else {
// //       setSearchResults([]);
// //     }
// //   };

// //   if (loading) {
// //     return (
// //       <div className="loading-spin">
// //         <Spin tip="Loading..."></Spin>
// //       </div>
// //     );
// //   }

// //   const displayedProducts = searchResults.length > 0 ? searchResults : products.filter(
// //     (product) => product.category === selectedCategory
// //   );

// //   const highlightedCategory = searchResults.length > 0 
// //     ? searchResults[0]?.category 
// //     : selectedCategory;

// //   return (
// //     <>
// //       <div className="cart-page-layout">
// //         <div className="main-screen">
// //         <Header onSearch={handleSearch} />
// //           {isSmallScreen && (
// //             <Button
// //               className="menu-button"
// //               onClick={() => setIsSidebarVisible(true)}
// //               icon={<DoubleRightOutlined />}
// //             />
// //           )}
// //           <div className="left-cont">
// //             <Sidebar
// //               categories={categories}
// //               onSelectCategory={setSelectedCategory}
// //               selectedCategory={highlightedCategory}
// //               isVisible={isSidebarVisible}
// //               onClose={() => setIsSidebarVisible(false)}
// //               isSmallScreen={isSmallScreen}
// //             />
// //             <ProductCatalog
// //               categoryName={highlightedCategory}
// //               products={displayedProducts}
// //               onAddToCart={handleAddToCart}
// //             />
// //           </div>
// //           <Footer />
// //         </div>
// //         <div
// //           className={`cart-container ${isCartVisible ? "cart-visible" : ""}`}
// //         >
// //           <Cart
// //             cartItems={cartItems}
// //             onRemoveFromCart={handleRemoveFromCart}
// //             onUpdateCartItem={handleUpdateCartItem}
// //           />
// //         </div>
// //         <Button
// //           className="cart-button"
// //           onClick={() => setIsCartVisible(!isCartVisible)}
// //           icon={<ShoppingCartOutlined />}
// //         />
// //         {popupProduct && (
// //           <ProductPopup
// //             product={popupProduct}
// //             onAddItem={handleAddItem}
// //             onClose={() => setPopupProduct(null)}
// //           />
// //         )}
// //       </div>
// //     </>
// //   );
// // };

// // export default MainScreen;

import React, { useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ChangeLanguage = ({ onLanguageChange }) => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    setSelectedLanguage(value);
    onLanguageChange(); // Trigger the callback to switch to "Basic Info"
  };

  return (
    <div>
      <h3 className="change-language-heading">{t('languageChange.heading')}</h3>
      <Select
        value={selectedLanguage}
        onChange={handleChangeLanguage}
        className="custom-dropdown"
      >
        <Option value="en">English</Option>
        <Option value="fr">Français</Option>
        {/* Add more languages as needed */}
      </Select>
    </div>
  );
};

export default ChangeLanguage;

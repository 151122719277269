// import React, { useState } from "react";
// import { useOpenShift } from "../components/OpenShiftContext";
// import Layout from "../components/Layout";
// import ChangePassword from "../components/ChangePassword";
// import BasicInfo from "../components/BasicInfo";
// // import Finance from "../components/Finance";
// import IconPassword from "../assets/images/icon-changePwd.svg";
// import IconFinance from "../assets/images/icon-finance.svg";
// import IconLogout from "../assets/images/icon-logout.svg";
// import { Drawer, Button } from "antd";
// import { DoubleRightOutlined } from "@ant-design/icons"; 
// import useIsSmallScreen from "../hooks/useIsSmallScreen";
// import { getCloseShiftdata } from "../modules/LandingPage";

// const MyAccount = () => {
//   const { openShiftData } = useOpenShift();
//   const { cashBalance, digitalBalance, selectedProfile } = openShiftData;
//   const [selectedTab, setSelectedTab] = useState("Basic Info");
//   const [isSidebarVisible, setIsSidebarVisible] = useState(false);

//   const isSmallScreen = useIsSmallScreen();  

//   const handleLogout=async()=>{
//     const a=await getCloseShiftdata()
//     console.log("***************Logout****************************",a);

//    const localDataOpenShift=localStorage.getItem('openingShiftResponse')
//   //  const posname=localDataOpenShift.message.pos_opening_shift.name
    
//   }

//   const renderContent = () => {
//     switch (selectedTab) {
//       case "Basic Info":
//         return <BasicInfo />;
//       case "Change Password":
//         return <ChangePassword />;
//       // case "Finance":
//       //   return <Finance />;
//       default:
//         return null;
//     }
//   };

//   const sidebarContent = (
//     <ul>
//       <li
//         className={selectedTab === "Basic Info" ? "active" : ""}
//         onClick={() => setSelectedTab("Basic Info")}
//       >
//         <span>
//           <img src={IconPassword} alt="Basic Info" />
//           Basic Info
//         </span>
//       </li>
//       <li
//         className={selectedTab === "Change Password" ? "active" : ""}
//         onClick={() => setSelectedTab("Change Password")}
//       >
//         <span>
//           <img src={IconPassword} alt="Change Password" />
//           Change Password
//         </span>
//       </li>
//       <li
//         className={selectedTab === "Finance" ? "active" : ""}
//         onClick={() => setSelectedTab("Finance")}
//       >
//         {/* <span>
//           <img src={IconFinance} alt="Finance" />
//           Finance
//         </span> */}
//       </li>
//       <li onClick={handleLogout}>
//         <a href="/closeshift">
//           <img src={IconLogout} alt="Close Shift" />
//           Close Shift 
//         </a>
//       </li>
//     </ul>
//   );

//   return (
//     <Layout>
//       {isSmallScreen && (
//         <Button
//           className="menu-button"
//           onClick={() => setIsSidebarVisible(true)}
//           icon={<DoubleRightOutlined />}
//         />
//       )}
//       <div className="account-page">
//         {isSmallScreen ? (
//           <Drawer
//             title="Profile"
//             placement="left"
//             closable={true}
//             onClose={() => setIsSidebarVisible(false)}
//             visible={isSidebarVisible}
//             bodyStyle={{ padding: 0 }}
//           >
//             <div className="profile-sidebar" onClick={() => setIsSidebarVisible(false)}>
//               {sidebarContent}
//             </div>
//           </Drawer>
//         ) : (
//           <div className="profile-sidebar">
//             {sidebarContent}
//           </div>
//         )}
//         <div className="content-right">{renderContent()}</div>
//       </div>
//     </Layout>
//   );
// };

// export default MyAccount;

// src/pages/MyAccount.js
// import React, { useState } from "react";
// import { useOpenShift } from "../components/OpenShiftContext";
// import Layout from "../components/Layout";
// import ChangePassword from "../components/ChangePassword";
// import BasicInfo from "../components/BasicInfo";
// // import Finance from "../components/Finance";
// import IconPassword from "../assets/images/icon-changePwd.svg";
// import IconFinance from "../assets/images/icon-finance.svg";
// import IconLogout from "../assets/images/icon-logout.svg";
// import { Drawer, Button } from "antd";
// import { DoubleRightOutlined } from "@ant-design/icons"; 
// import useIsSmallScreen from "../hooks/useIsSmallScreen";
// import { getCloseShiftdata } from "../modules/LandingPage";
// import { useTranslation } from 'react-i18next';

// const MyAccount = () => {
//   const { t } = useTranslation();
//   const { openShiftData } = useOpenShift();
//   const { cashBalance, digitalBalance, selectedProfile } = openShiftData;
//   const [selectedTab, setSelectedTab] = useState("Basic Info");
//   const [isSidebarVisible, setIsSidebarVisible] = useState(false);

//   const isSmallScreen = useIsSmallScreen();  

//   const handleLogout = async () => {
//     const a = await getCloseShiftdata();
//     console.log("***************Logout****************************", a);

//     const localDataOpenShift = localStorage.getItem('openingShiftResponse');
//     // const posname = localDataOpenShift.message.pos_opening_shift.name
//   };

//   const renderContent = () => {
//     switch (selectedTab) {
//       case t('profilepage.basicInfo'):
//         return <BasicInfo />;
//       case t('profilepage.changePassword'):
//         return <ChangePassword />;
//       // case t('finance'):
//       //   return <Finance />;
//       default:
//         return null;
//     }
//   };

//   const sidebarContent = (
//     <ul>
//       <li
//         className={selectedTab === t('profilepage.basicInfo') ? "active" : ""}
//         onClick={() => setSelectedTab(t('profilepage.basicInfo'))}
//       >
//         <span>
//           <img src={IconPassword} alt={t('profilepage.basicInfo')} />
//           {t('profilepage.basicInfo')}
//         </span>
//       </li>
//       <li
//         className={selectedTab === t('profilepage.changePassword') ? "active" : ""}
//         onClick={() => setSelectedTab(t('profilepage.changePassword'))}
//       >
//         <span>
//           <img src={IconPassword} alt={t('profilepage.changePassword')} />
//           {t('profilepage.changePassword')}
//         </span>
//       </li>
//       <li
//         className={selectedTab === t('profilepage.finance') ? "active" : ""}
//         onClick={() => setSelectedTab(t('profilepage.finance'))}
//       >
//         {/* <span>
//           <img src={IconFinance} alt={t('finance')} />
//           {t('finance')}
//         </span> */}
//       </li>
//       <li onClick={handleLogout}>
//         <a href="/closeshift">
//           <img src={IconLogout} alt={t('profilepage.closeShift')} />
//           {t('profilepage.closeShift')}
//         </a>
//       </li>
//     </ul>
//   );

//   return (
//     <Layout>
//       {isSmallScreen && (
//         <Button
//           className="menu-button"
//           onClick={() => setIsSidebarVisible(true)}
//           icon={<DoubleRightOutlined />}
//         />
//       )}
//       <div className="account-page">
//         {isSmallScreen ? (
//           <Drawer
//             title={t('profilepage.profile')}
//             placement="left"
//             closable={true}
//             onClose={() => setIsSidebarVisible(false)}
//             visible={isSidebarVisible}
//             bodyStyle={{ padding: 0 }}
//           >
//             <div className="profile-sidebar" onClick={() => setIsSidebarVisible(false)}>
//               {sidebarContent}
//             </div>
//           </Drawer>
//         ) : (
//           <div className="profile-sidebar">
//             {sidebarContent}
//           </div>
//         )}
//         <div className="content-right">{renderContent()}</div>
//       </div>
//     </Layout>
//   );
// };

// export default MyAccount;

import React, { useState } from "react";
import { useOpenShift } from "../components/OpenShiftContext";
import Layout from "../components/Layout";
import ChangePassword from "../components/ChangePassword";
import BasicInfo from "../components/BasicInfo";
import ChangeLanguage from "../components/ChangeLanguge"; // Import ChangeLanguage component
import IconPassword from "../assets/images/icon-changePwd.svg";
import IconFinance from "../assets/images/icon-finance.svg";
import IconLogout from "../assets/images/icon-logout.svg";
import { Drawer, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import useIsSmallScreen from "../hooks/useIsSmallScreen";
import { useTranslation } from 'react-i18next';
import { getCloseShiftdata } from "../modules/LandingPage";

const MyAccount = () => {
  const { t } = useTranslation();
  const { openShiftData } = useOpenShift();
  const [selectedTab, setSelectedTab] = useState("Basic Info");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const isSmallScreen = useIsSmallScreen();
  
  const handleLogout = async () => {
    const a = await getCloseShiftdata();
    console.log("***************Logout****************************", a);
  };

  const handleLanguageChange = () => {
    setSelectedTab(t('profilepage.basicInfo')); // Automatically switch to "Basic Info" after language change
  };

  const renderContent = () => {
    switch (selectedTab) {
      case t('profilepage.basicInfo'):
        return <BasicInfo />;
      case t('profilepage.changePassword'):
        return <ChangePassword />;
      case t('profilepage.changeLanguage'):
        return <ChangeLanguage onLanguageChange={handleLanguageChange} />; // Pass the callback
      default:
        return null;
    }
  };

  const sidebarContent = (
    <ul>
      <li
        className={selectedTab === t('profilepage.basicInfo') ? "active" : ""}
        onClick={() => setSelectedTab(t('profilepage.basicInfo'))}
      >
        <span>
          <img src={IconPassword} alt={t('profilepage.basicInfo')} />
          {t('profilepage.basicInfo')}
        </span>
      </li>
      <li
        className={selectedTab === t('profilepage.changePassword') ? "active" : ""}
        onClick={() => setSelectedTab(t('profilepage.changePassword'))}
      >
        <span>
          <img src={IconPassword} alt={t('profilepage.changePassword')} />
          {t('profilepage.changePassword')}
        </span>
      </li>
      <li
        className={selectedTab === t('profilepage.changeLanguage') ? "active" : ""}
        onClick={() => setSelectedTab(t('profilepage.changeLanguage'))}
      >
        <span>
          <img src={IconFinance} alt={t('profilepage.changeLanguage')} />
          {t('profilepage.changeLanguage')}
        </span>
      </li>
      <li onClick={handleLogout}>
        <a href="/closeshift">
          <img src={IconLogout} alt={t('profilepage.closeShift')} />
          {t('profilepage.closeShift')}
        </a>
      </li>
    </ul>
  );

  return (
    <Layout>
      {isSmallScreen && (
        <Button
          className="menu-button"
          onClick={() => setIsSidebarVisible(true)}
          icon={<DoubleRightOutlined />}
        />
      )}
      <div className="account-page">
        {isSmallScreen ? (
          <Drawer
            title={t('profilepage.profile')}
            placement="left"
            closable={true}
            onClose={() => setIsSidebarVisible(false)}
            visible={isSidebarVisible}
            bodyStyle={{ padding: 0 }}
          >
            <div className="profile-sidebar" onClick={() => setIsSidebarVisible(false)}>
              {sidebarContent}
            </div>
          </Drawer>
        ) : (
          <div className="profile-sidebar">
            {sidebarContent}
          </div>
        )}
        <div className="content-right">{renderContent()}</div>
      </div>
    </Layout>
  );
};

export default MyAccount;



import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import Pagination from "./pagination"; // Import the Pagination component
import { useTranslation } from 'react-i18next'; // Import useTranslation

const ProductCatalog = ({ categoryName, products, onAddToCart }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 12;
  const { t } = useTranslation(); // Use useTranslation

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Reset pagination when categoryName changes
  useEffect(() => {
    setCurrentPage(1);
  }, [categoryName]);

  // Calculate the customers to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentCustomers = products.slice(startIndex, startIndex + productsPerPage);
 // const [currentPage, setCurrentPage] = useState(1);
  // const [productsPerPage, setProductsPerPage] = useState(10);

  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth;
  //     if (width >= 1720) {
  //       setProductsPerPage(10);
  //     } else if (width >= 1430) {
  //       setProductsPerPage(8);
  //     } else if (width >= 1230) {
  //         setProductsPerPage(8);
  //       }
  //      else if (width >= 1150) {
  //       setProductsPerPage(6);
  //     } else {
  //       setProductsPerPage(4);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   handleResize(); // Set initial value based on current window size

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // Calculate the indexes for slicing the products array
  // const indexOfLastProduct = currentPage * productsPerPage;
  // const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  // // Determine total number of pages
  // const totalPages = Math.ceil(products.length / productsPerPage);

  // // Function to handle page changes
  // const handlePageChange = (direction) => {
  //   setCurrentPage((prevPage) => {
  //     if (direction === "next" && prevPage < totalPages) {
  //       return prevPage + 1;
  //     }
  //     if (direction === "prev" && prevPage > 1) {
  //       return prevPage - 1;
  //     }
  //     return prevPage;
  //   });
  // };
  return (
    <div className="product-catalog">
    <h2>{categoryName}</h2>
      <div className="product-list">
        {currentCustomers.length > 0 ? (
          currentCustomers.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onAddToCart={onAddToCart}
            />
          ))
        ) : (
          <p>{t('productCatalog.noProducts')}</p>
        )}
      </div>
      <Pagination
        totalItems={products.length}
        itemsPerPage={productsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ProductCatalog;
// import React, { useState, useEffect } from "react";
// import ProductCard from "./ProductCard";
// import Pagination from "./pagination"; // Import the Pagination component

// const ProductCatalog = ({ categoryName, products, onAddToCart }) => {
//   const [currentPage, setCurrentPage] = useState(1);
//   // const [productsPerPage, setProductsPerPage] = useState(10);
//   let productsPerPage=12
//   // useEffect(() => {
//   //   const handleResize = () => {
//   //     const width = window.innerWidth;
//   //     if (width >= 1720) {
//   //       setProductsPerPage(10);
//   //     } else if (width >= 1430) {
//   //       setProductsPerPage(8);
//   //     } else if (width >= 1230) {
//   //       setProductsPerPage(8);
//   //     }
//   //     else if (width >= 1150) {
//   //       setProductsPerPage(6);
//   //     } else {
//   //       setProductsPerPage(4);
//   //     }
//   //   };

//   //   window.addEventListener("resize", handleResize);
//   //   handleResize(); // Set initial value based on current window size

//   //   return () => {
//   //     window.removeEventListener("resize", handleResize);
//   //   };
//   // }, []);

//   // Calculate the indexes for slicing the products array
//   // const indexOfLastProduct = currentPage * productsPerPage;
//   // const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   // const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

//   // // Determine total number of pages
//   // const totalPages = Math.ceil(products.length / productsPerPage);
  
//   // Function to handle page changes
//   // const handlePageChange = (direction) => {
//   //   setCurrentPage((prevPage) => {
//   //     if (direction === "next" && prevPage < totalPages) {
//   //       return prevPage + 1;
//   //     }
//   //     if (direction === "prev" && prevPage > 1) {
//   //       return prevPage - 1;
//   //     }
//   //     return prevPage;
//   //   });
//   // };
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };
//   // Calculate the customers to display on the current page
//   const startIndex = (currentPage - 1) * productsPerPage;
//   const currentCustomers = products.slice(startIndex, startIndex + productsPerPage);

//   // const [currentPage, setCurrentPage] = useState(1);
//   // const [productsPerPage, setProductsPerPage] = useState(10);

//   // useEffect(() => {
//   //   const handleResize = () => {
//   //     const width = window.innerWidth;
//   //     if (width >= 1720) {
//   //       setProductsPerPage(10);
//   //     } else if (width >= 1430) {
//   //       setProductsPerPage(8);
//   //     } else if (width >= 1230) {
//   //         setProductsPerPage(8);
//   //       }
//   //      else if (width >= 1150) {
//   //       setProductsPerPage(6);
//   //     } else {
//   //       setProductsPerPage(4);
//   //     }
//   //   };

//   //   window.addEventListener("resize", handleResize);
//   //   handleResize(); // Set initial value based on current window size

//   //   return () => {
//   //     window.removeEventListener("resize", handleResize);
//   //   };
//   // }, []);

//   // Calculate the indexes for slicing the products array
//   // const indexOfLastProduct = currentPage * productsPerPage;
//   // const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   // const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

//   // // Determine total number of pages
//   // const totalPages = Math.ceil(products.length / productsPerPage);

//   // // Function to handle page changes
//   // const handlePageChange = (direction) => {
//   //   setCurrentPage((prevPage) => {
//   //     if (direction === "next" && prevPage < totalPages) {
//   //       return prevPage + 1;
//   //     }
//   //     if (direction === "prev" && prevPage > 1) {
//   //       return prevPage - 1;
//   //     }
//   //     return prevPage;
//   //   });
//   // };

//   return (
//     <div className="product-catalog">
//     <h2>{categoryName}</h2>
//     <div className="product-list">
//       {currentCustomers.length > 0 ? (
//         currentCustomers.map((product) => (
//           <ProductCard
//             key={product.id}
//             product={product}
//             onAddToCart={onAddToCart}
//           />
//         ))
//       ) : (
//         <p>No products available</p>
//       )}
//     </div>
//     <Pagination
//       totalItems={products.length}
//       itemsPerPage={productsPerPage}
//       currentPage={currentPage}
//       onPageChange={handlePageChange}
//     />
//   </div>
//   );
// };

// export default ProductCatalog;

export function SearchCustomerFromList(customers, mobileNumber, email) {
    const validate = { email: true, mobileNumber: true };
    for (let i = 0; i < customers.length; i++) {
        const customer = customers[i];
        if (mobileNumber && customer.mobile_no === mobileNumber) {
            validate.mobileNumber = false;
        }
        if (email && customer.email_id === email) {
            validate.email = false;
        }
        if (!validate.mobileNumber && !validate.email) {
            break;  
        }
    }
    return validate;
}
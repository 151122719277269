
import React, { useState, useContext, useEffect } from "react";
import NoImage from "../assets/images/no-img.png";
import Close from "../assets/images/cross.png";
import { CartContext } from "../common/CartContext";
import { Modal } from "antd";
import { itemWarehouseDetails } from "../modules/LandingPage";

const ProductPopup = ({ product, onClose, selectedCustomer }) => {
  const [quantity, setQuantity] = useState(1);
  const { addItemToCart, cartItems } = useContext(CartContext); // Access cartItems from CartContext
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (product && product.name) {
          const item_code = product.name.trim();
          const result = await itemWarehouseDetails(item_code);
          setData(result);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [product]);

  useEffect(() => {
    if (!selectedCustomer) {
      const storedCustomer = localStorage.getItem("selectedCustomer");
      if (storedCustomer) {
        selectedCustomer = JSON.parse(storedCustomer);
      }
    }
  }, [selectedCustomer]);

  const handleAddItem = () => {
    const selectedCustomer = getSelectedCustomer();
    if (!selectedCustomer) {
      Modal.error({
        title: "Attention!",
        content: "Please select a customer before adding items to the cart.",
      });
      return;
    }

    if (!quantity || quantity < 1 || isNaN(quantity)) {
      Modal.error({
        title: "Invalid Quantity",
        content: "Please enter a valid quantity (at least 1) before adding to the cart.",
      });
      return;
    }

    // Check if product is already in the cart
    const isProductInCart = cartItems.some((item) => item.name === product.name);
    if (isProductInCart) {
      Modal.error({
        title: "Product Already in Cart",
        content: "This product is already added to your cart. You cannot add it again.",
      });
      return;
    }

    if (quantity > product.stock_qty) {
      Modal.error({
        title: "Stock Exceeded",
        content: `The available stock for this product is ${product.stock_qty}. You cannot select more than this quantity.`,
      });
      return;
    }

    const updatedProduct = { ...product, quantity };
    addItemToCart(updatedProduct);
    onClose();
  };

  const getSelectedCustomer = () => {
    const customer = localStorage.getItem("selectedCustomer");
    return customer ? JSON.parse(customer) : null;
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setQuantity(value === "" ? "" : parseInt(value, 10));
    }
  };

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const renderPriceDetails = () => {
    if (!data || !data.price) return null;
    return data.price.map((priceItem) => (
      <p key={priceItem.price_list}>
        {priceItem.price_list}: <span>${priceItem.price_list_rate.toFixed(2)}</span>
      </p>
    ));
  };

  const renderWarehouseDetails = () => {
    if (!data || !data.item_warehouse_list) return null;
    return data.item_warehouse_list.map((warehouseItem, index) => (
      <tr key={warehouseItem.warehouse}>
        <td>{index + 1}</td>
        <td>{warehouseItem.item_code}</td>
        <td>{warehouseItem.warehouse}</td>
        <td>{warehouseItem.qty_after_transaction}</td>
      </tr>
    ));
  };

  return (
    <>
      <div className="overlay"></div>
      <div className="product-popup">
        <div className="popup-head">
          <button className="popup-close" onClick={onClose}>
            <img src={Close} alt="close" />
          </button>
        </div>
        <div className="popup-body">
          <div className="heading-img">
            <span>
              <img src={product.image || NoImage} alt={product.name} />
            </span>
            <h2>{product.name}</h2>
          </div>
          {data && data.price && (
            <div className="price-details">{renderPriceDetails()}</div>
          )}
          {data && data.item_warehouse_list && (
            <div className="warehouse-details">
              <table>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Item Name</th>
                    <th>Warehouse Name</th>
                    <th>Stock</th>
                  </tr>
                </thead>
                <tbody>{renderWarehouseDetails()}</tbody>
              </table>
            </div>
          )}
          <div className="popup-qty">
            <label>
              <div className="quantity-control">
                <button onClick={decrementQuantity}>-</button>
                <input
                  type="text"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
                <button onClick={incrementQuantity}>+</button>
              </div>
            </label>
          </div>
          <div className="popup-footer">
            <div className="btn-total">
              Item Total <br />
              <span>${(product.product_price * quantity).toFixed(2)}</span>
            </div>
            <button onClick={handleAddItem}>Add to Cart</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPopup;
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { fetchforCustomers, updateCustomer } from "../modules/LandingPage";
import SearchIcon from "../assets/images/icon-search.png";
import EditIcon from "../assets/images/icon-edit.png";
import { Button, Input, Modal, Spin, notification } from "antd";
import Pagination from "../components/pagination"; // Import the Pagination component
import { useTranslation } from "react-i18next";
const CustomerPage = () => {

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    name: "",
    mobile: "",
    email: "",
    pinCode: "",
    language: "",
  });
  const [filterVisible, setFilterVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [newEmailID, setNewEmailID] = useState("");
  const [errors, setErrors] = useState({});
  // const [currentPage, setCurrentPage] = useState(1);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  // const customersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(9);
  const itemsPerPage = 6;
  const { t } = useTranslation(); 


  // Function to handle page changes



  useEffect(() => {
    const loadCustomers = async () => {
      try {
        const customerData = await fetchforCustomers();
        setCustomers(customerData);
        setFilteredCustomers(customerData); // Initially set filteredCustomers to all customers
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadCustomers();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const handlePageChange = (direction) => {
  //   setCurrentPage((prevPage) => {
  //     if (direction === "next" && prevPage < totalPages) {
  //       return prevPage + 1;
  //     }
  //     if (direction === "prev" && prevPage > 1) {
  //       return prevPage - 1;
  //     }
  //     return prevPage;
  //   });
  // };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Calculate the customers to display on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentCustomers = filteredCustomers.slice(startIndex, startIndex + itemsPerPage);

  const filteredCustomer = customers.filter((customer) =>
    customer.customer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.mobile_no.includes(searchTerm)
  );

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^\d{10}$/;
    return re.test(String(mobile));
  };

  const handleUpdateCustomerClick = (customer) => {
    setSelectedCustomer(customer);
    setNewName(customer.customer_name);
    setNewMobile(customer.mobile_no);
    // console.log(customer.email_id);
    setNewEmailID(customer.email_id);
    setIsModalOpen(true);
  };

  // const handleUpdateCustomer = async () => {
  //   let validationErrors = {};

  //   if (newEmailID && !validateEmail(newEmailID)) {
  //     validationErrors.email = t("validation.email");
  //   }
  //   if (!validateMobile(newMobile)) {
  //     validationErrors.mobile = t("validation.mobile");
  //   }

  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     notification.error({
  //       message: t("notification.validationError"),
  //       description: t("notification.validationDescription"),
  //     });
  //     return;
  //   }

  //   try {
  //     const updatedCustomer = await updateCustomer({
  //       name: selectedCustomer.name,
  //       customer_name: newName,
  //       mobile_no: newMobile,
  //       email_id: newEmailID,
  //     });
  //     setCustomers((prevCustomers) =>
  //       prevCustomers.map((cust) =>
  //         cust.name === updatedCustomer.name
  //           ? { ...updatedCustomer, email_id: newEmailID }
  //           : cust
  //       )
  //     );
  //     setIsModalOpen(false);
  //     setErrors({});
  //   } catch (error) {
  //     console.error(t("error.updateCustomer"), error);
  //   }
  // };
  const handleUpdateCustomer = async () => {
    let validationErrors = {};
  
    // Validate email and mobile number formats
    if (newEmailID && !validateEmail(newEmailID)) {
      validationErrors.email = t("validation.email");
    }
    if (!validateMobile(newMobile)) {
      validationErrors.mobile = t("validation.mobile");
    }
  
    // Check if mobile number already exists in other customer records
    const isMobileExists = customers.some(
      (customer) => customer.mobile_no === newMobile
    );
  
    if (isMobileExists) {
      notification.error({
        message: t("notification.mobileExistsError"),
        description: t("notification.mobileExistsDescription"),
      });
      return;
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      notification.error({
        message: t("notification.validationError"),
        description: t("notification.validationDescription"),
      });
      return;
    }
  
    try {
      // Proceed with API call if the mobile number doesn't exist
      const updatedCustomer = await updateCustomer({
        name: selectedCustomer.name,
        customer_name: newName,
        mobile_no: newMobile,
        email_id: newEmailID,
      });
  
      // Refetch the customer data after the update
      const customerData = await fetchforCustomers();
      setCustomers(customerData);
      setFilteredCustomers(customerData);
  
      setIsModalOpen(false);
      setErrors({});
      notification.success({
        message: t("notification.updateSuccess"),
        description: t("notification.customerUpdated"),
      });
    } catch (error) {
      console.error(t("error.updateCustomer"), error);
      notification.error({
        message: t("notification.updateError"),
        description: t("notification.customerUpdateFailed"),
      });
    }
  };
  

  const handleEmailChange = (e) => {
    setNewEmailID(e.target.value);
  };

  // const handleFilterApply = () => {
  //   let filtered = customers.filter((customer) => {
  //     let matches = true;
  //     // console.log(customer.customer_name, "Customer");
  //     // console.log(filters.name, "filter name")

  //     // Check if customer name contains the filter name
  //     if (
  //       filters.name && !customer.customer_name
  //         .toLowerCase()
  //         .includes(filters.name.toLowerCase())
  //     ) {
  //       matches = false;
  //     }



  //     // Check if customer mobile matches the filter mobile
  //     if (filters.mobile && customer.mobile_no !== filters.mobile) {
  //       matches = false;
  //     }

  //     // Check if customer email contains the filter email
  //     if (
  //       filters.email &&
  //       !customer.email_id.toLowerCase().includes(filters.email.toLowerCase())
  //     ) {
  //       matches = false;
  //     }

  //     // Check if customer pinCode matches the filter pinCode
  //     if (filters.pinCode && customer.pinCode !== filters.pinCode) {
  //       matches = false;
  //     }

  //     // Check if customer language matches the filter language
  //     if (
  //       filters.language &&
  //       customer.language.toLowerCase() !== filters.language.toLowerCase()
  //     ) {
  //       matches = false;
  //     }

  //     return matches;
  //   });

  //   // Sort filtered customers by name
  //   filtered.sort((a, b) => a.customer_name.localeCompare(b.customer_name));

  //   setFilteredCustomers(filtered);
  //   setFilterVisible(false);
  // };
  const handleFilterApply = () => {
    let filtered = customers.filter((customer) => {
      let matches = true;
  
      // Check if customer name contains the filter name
      if (filters.name && !customer.customer_name.toLowerCase().includes(filters.name.toLowerCase())) {
        matches = false;
      }
  
      // Check if customer mobile matches the filter mobile
      if (filters.mobile && customer.mobile_no !== filters.mobile) {
        matches = false;
      }
  
      // Check if customer email contains the filter email
      if (filters.email && !customer.email_id.toLowerCase().includes(filters.email.toLowerCase())) {
        matches = false;
      }
  
      // Check if customer pinCode matches the filter pinCode
      if (filters.pinCode && customer.pinCode !== filters.pinCode) {
        matches = false;
      }
  
      // Check if customer language matches the filter language
      if (filters.language && customer.language.toLowerCase() !== filters.language.toLowerCase()) {
        matches = false;
      }
  
      return matches;
    });
  
    // Sort filtered customers by name
    filtered.sort((a, b) => a.customer_name.localeCompare(b.customer_name));
  
    setFilteredCustomers(filtered);
    setCurrentPage(1); // Reset to the first page after applying filters
    setFilterVisible(false);
  };
  

  const handleFilterClear = () => {
    setFilters({
      name: "",
      mobile: "",
      email: "",
      pinCode: "",
      language: "",
    });
    setFilteredCustomers(customers);
    setFilterVisible(false);
  };


  // Calculate the indexes for slicing the products array
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredCustomers.slice(indexOfFirstProduct, indexOfLastProduct);
  // Determine total number of pages
  const totalPages = Math.ceil(filteredCustomers.length / productsPerPage);





  return (
    <Layout>
      <div className="main-cont customer-page">
        <div className="heading-cont">
          <h1>{t("customerPage.title")}</h1>
          <div className="filter-section">
            <button onClick={handleFilterClear} className="filter-btn">
            {t("customerPage.clearFilter")}
            </button>
            <button onClick={handleFilterClick} className="filter-btn">
            {t("customerPage.filter")}
            </button>
            {filterVisible && (
              <div className="filter-dropdown">
                <input
                  type="text"
                  name="name"
                  placeholder={t("customerPage.searchName")}
                  value={filters.name}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  name="mobile"
                  placeholder={t("customerPage.searchMobile")}
                  value={filters.mobile}
                  onChange={handleFilterChange}
                />
                <input
                  type="text"
                  name="email"
                  placeholder={t("customerPage.searchEmail")}
                  value={filters.email}
                  onChange={handleFilterChange}
                />
                {/* <input
                  type="text"
                  name="pinCode"
                  placeholder="Search Pin Code"
                  value={filters.pinCode}
                  onChange={handleFilterChange}
                /> */}
                {/* <select
                  name="language"
                  value={filters.language}
                  onChange={handleFilterChange}
                >
                  <option value="">Select Language</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Punjabi">Punjabi</option>
                </select> */}
                <button className="apply-btn" onClick={handleFilterApply}>
                {t("customerPage.apply")}
                </button>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="loading-spin">
            <Spin tip={t("loading")}></Spin>
          </div>
        ) : error ? (
          <p>{t("error.loadingCustomers")}:{error}</p>
        ) : (

          <ul className="customer-list">

            {currentProducts.map((customer, index) => (
              <li key={index}>
                <p>
                  <span className="cus-name">{customer.customer_name}</span>
                  <span className="cus-mobile">{customer.mobile_no}</span>
                </p>
                <p>
                  <span className="cus-email">{customer.email_id}</span>
                  <button
                    className="cus-edit"
                    onClick={() => handleUpdateCustomerClick(customer)}
                  >
                    <img src={EditIcon} alt="Edit" />
                  </button>
                </p>
              </li>
            ))}
            <div className="pagena">
              <Pagination
                totalItems={filteredCustomers.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </ul>

        )}

      </div>
      <Modal
        title={t("modal.updateCustomer")}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleUpdateCustomer}>
            Update
          </Button>,
        ]}
        className="edit-customer-modal"
      >
        <div className="edit-cus-row">
          <Input
            type="text"
            placeholder={t("modal.name")}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="edit-cus-row"
          />
        </div>
        <div className="edit-cus-row">
          <Input
            type="text"
            placeholder=  {t("modal.mobile")}
            value={newMobile}
            onChange={(e) => setNewMobile(e.target.value)}
            className="edit-cus-row"
          />
          {errors.mobile && <p style={{ color: 'red' }}>{errors.mobile}</p>}
        </div>
        {/* <div className="edit-cus-row"> */}
        <div >
          <Input
            type="email"
            placeholder={t("modal.email")}
            value={newEmailID}
            onChange={handleEmailChange}
            className="edit-cus-row_email"
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
        </div>
      </Modal>
    </Layout>
  );
};


export default CustomerPage;

import React, { useEffect, useState } from "react";
import Logo from "../assets/images/steves-favicon-red.png";
import { useLocation } from "react-router-dom";

const Receipt = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  // console.log("data of REciept",data);

  const calculateSubTotal = (items) => {
    if (!Array.isArray(items)) {
      return 0;
    }
    return items.reduce((sum, item) => sum + item.amount, 0);
  };
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const originalData = JSON.parse(queryParams.get("state"));
    if (originalData) {
      setData(originalData);
    }
  }, [location.search]);

  const [costCenter, setCostCenter] = useState("");

  useEffect(() => {
    const storedCostCenter = localStorage.getItem("costCenter");
    if (storedCostCenter) {
      setCostCenter(storedCostCenter);
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  };

  return (
    <>
      {data && (
        <section className="wrapper_receipt">
          <section className="container_receipt">
            <main className="maindiv_receipt">
              <div className="LogoXelato_receipt">
                <img src={Logo} alt="XelatoLogo" className="logo_img" />
              </div>
              <div className="address_receipt">
                <div>
                  <h3 className="gelato_heading__receipt">
                    STEVE'S MUSIC STORE
                  </h3>
                </div>
                <div className="sub_address_receipt">
                  <p className="para_receipt">{costCenter}</p>
                  {/* <p className='para_receipt'>XTConICE Gelato Limited</p> */}
                  {/* <p className='para_receipt'>Shop 4B, 45-47 Cochrane Street</p> */}
                  {/* <p className='para_receipt'>Central, Hong Kong SAR</p> */}
                  <p className="para_receipt">
                    (Res.) 514 878-2216 (Off.) 514 878-3976{" "}
                  </p>
                </div>
              </div>
            </main>
            <hr className="hr" />
            <div className="middle_div">
              <div className="date_receipt">
                <p className="para_receipt">Sales Invoice No, : {data.response.sales}</p>
                {/* <p className="para_receipt">Check#: {data.response.name}</p> */}
                {/* <p className="para_receipt">Date: {formatDate(data.response.creation)}</p> */}
                <p className="para_receipt">{`${(data.response.transaction_date)} ${(data.response.transaction_time)}`}</p>

                <p className="para_receipt">Cashier: {data.response.hub_manager_name}</p>
              </div>
              <hr className="hr" />
              <div className="date_receipt">
                <p className="para_receipt">
                  Customer Id: {data.response.customer}
                </p>
                {/* <p className='para_receipt'>Date: 29/5/2024 - 13:30:27</p> */}
                {/* <p className='para_receipt'>Date: {formatDateTime(data.creation)}</p> */}
                <p className="para_receipt">
                  Customer Name: {data.response.customer_name}
                </p>
                <br />
                <p className="para_receipt date_reciept">Items: </p>
              </div>
              <ol className="list_receipt" start="1">
                {data.response.items.map((res, index) => (
                  <li key={index}>
                    <div className="sublist__receipt">
                      <p className="para_receipt">
                        {/* {`${res.item_name}(${res.item_code})`} */}
                        {`${res.item_name}`}
                      </p>
                      <p className="para_receipt">{res.amount.toFixed(2)}</p>
                    </div>
                  </li>
                ))}
              </ol>
              <hr className="hr" />
              <div className="subtotal_receipt">
                <p className="para_receipt">Sub-total</p>
                <p className="para_receipt">
                  {calculateSubTotal(data.response.items).toFixed(2)}
                </p>
              </div>
              {/* ---------------------Discounts----------------------------------------- */}

              {data.response.coupon_code !== null && (
                <div className="subtotal_receipt">
                  <p className="para_receipt">Coupon Code-{data.response.coupon_code}</p>
                  <p className="para_receipt">- {data.response.discount_amount.toFixed(2)}</p>
                </div>
              )}
              {/* {data.gift_card!==null && <div className='subtotal_receipt'>
            <p className='para_receipt'>Gift Card-{data.gift_card}</p>
            <p className='para_receipt'>{data.discount_amount.toFixed(2)}</p>
          </div>
        } */}

              {data.response.loyalty_amount !== 0 && (
                <div className="subtotal_receipt">
                  <p className="para_receipt">Loyalty Points({data.response.loyalty_points})</p>
                  <p className="para_receipt">- {data.response.loyalty_amount.toFixed(2)}</p>
                </div>
              )}
              <ol className="list_receipt" start="1">
                {data.response.tax_detail.map((res, index) => (
                  <li key={index}>
                    <div className="subtotal_receipt">
                      <p className="para_receipt">
                        {`${res.account_head}(${res.description})`}
                      </p>
                      <p className="para_receipt">+ {res.tax_amount.toFixed(2)}</p>
                    </div>
                  </li>
                ))}
              </ol>
              <hr className="hr" />
              <div className="subtotal_receipt">
                <p className="para_receipt">Total</p>
                <p className="para_receipt">{data.response.grand_total.toFixed(2)-data.response.loyalty_amount.toFixed(2)}</p>
              </div>
            </div>
            <hr className="hr" />
            <div>
              <p className="para_receipt">Thank You. Please come again.</p>
              <p className="para_receipt">STEVESMUSIC</p>
            </div>
            <hr className="hr" />

            <p className="area_receipt">Area for changing promotions</p>
          </section>
        </section>
      )}
    </>
  );
};

export default Receipt;

// import React, { useEffect, useState } from "react";
// import Logo from "../assets/images/steves-favicon-red.png";
// import { useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";

// const Receipt = () => {
//   const { t } = useTranslation(); // Initialize translation hook
//   const location = useLocation();
//   const [data, setData] = useState(null);

//   const calculateSubTotal = (items) => {
//     if (!Array.isArray(items)) {
//       return 0;
//     }
//     return items.reduce((sum, item) => sum + item.amount, 0);
//   };

//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const originalData = JSON.parse(queryParams.get("state"));
//     if (originalData) {
//       setData(originalData);
//     }
//   }, [location.search]);

//   const [costCenter, setCostCenter] = useState("");

//   useEffect(() => {
//     const storedCostCenter = localStorage.getItem("costCenter");
//     if (storedCostCenter) {
//       setCostCenter(storedCostCenter);
//     }
//   }, []);

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleString("en-US", {
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "2-digit",
//       minute: "2-digit",
//       second: "2-digit",
//       hour12: false,
//     });
//   };

//   return (
//     <>
//       {data && (
//         <section className="wrapper_receipt">
//           <section className="container_receipt">
//             <main className="maindiv_receipt">
//               <div className="LogoXelato_receipt">
//                 <img src={Logo} alt={t('Receipt.logo_alt')} className="logo_img" />
//               </div>
//               <div className="address_receipt">
//                 <div>
//                   <h3 className="gelato_heading__receipt">
//                     {t('Receipt.store_name')}
//                   </h3>
//                 </div>
//                 <div className="sub_address_receipt">
//                   <p className="para_receipt">{costCenter}</p>
//                   {/* <p className='para_receipt'>XTConICE Gelato Limited</p> */}
//                   {/* <p className='para_receipt'>Shop 4B, 45-47 Cochrane Street</p> */}
//                   {/* <p className='para_receipt'>Central, Hong Kong SAR</p> */}
//                   <p className="para_receipt">
//                     (Res.) 514 878-2216 (Off.) 514 878-3976
//                   </p>
//                 </div>
//               </div>
//             </main>
//             <hr className="hr" />
//             <div className="middle_div">
//               <div className="date_receipt">
//                 <p className="para_receipt">
//                   {t('Receipt.sales_invoice_no')}: {data.response.sales_invoice}
//                 </p>
//                 <p className="para_receipt">
//                   {`${data.response.transaction_date} ${data.response.transaction_time}`}
//                 </p>
//                 <p className="para_receipt">
//                   {t('Receipt.cashier')}: {data.response.hub_manager_name}
//                 </p>
//               </div>
//               <hr className="hr" />
//               <div className="date_receipt">
//                 <p className="para_receipt">
//                   {t('Receipt.customer_id')}: {data.response.customer}
//                 </p>
//                 <p className="para_receipt">
//                   {t('Receipt.customer_name')}: {data.response.customer_name}
//                 </p>
//                 <br />
//                 <p className="para_receipt date_reciept">{t('Receipt.items')}:</p>
//               </div>
//               <ol className="list_receipt" start="1">
//                 {data.response.items.map((res, index) => (
//                   <li key={index}>
//                     <div className="sublist__receipt">
//                       <p className="para_receipt">
//                         {`${res.item_name}`}
//                       </p>
//                       <p className="para_receipt">{res.amount.toFixed(2)}</p>
//                     </div>
//                   </li>
//                 ))}
//               </ol>
//               <hr className="hr" />
//               <div className="subtotal_receipt">
//                 <p className="para_receipt">{t('Receipt.subtotal')}</p>
//                 <p className="para_receipt">
//                   {calculateSubTotal(data.response.items).toFixed(2)}
//                 </p>
//               </div>
//               {data.response.coupon_code !== null && (
//                 <div className="subtotal_receipt">
//                   <p className="para_receipt">
//                     {t('Receipt.coupon_code')}-{data.response.coupon_code}
//                   </p>
//                   <p className="para_receipt">
//                     - {data.response.discount_amount.toFixed(2)}
//                   </p>
//                 </div>
//               )}
//               {data.response.loyalty_amount !== 0 && (
//                 <div className="subtotal_receipt">
//                   <p className="para_receipt">
//                     {t('Receipt.loyalty_points')}({data.response.loyalty_points})
//                   </p>
//                   <p className="para_receipt">
//                     - {data.response.loyalty_amount.toFixed(2)}
//                   </p>
//                 </div>
//               )}
//               <ol className="list_receipt" start="1">
//                 {data.response.tax_detail.map((res, index) => (
//                   <li key={index}>
//                     <div className="subtotal_receipt">
//                       <p className="para_receipt">
//                         {`${res.account_head}(${res.description})`}
//                       </p>
//                       <p className="para_receipt">+ {res.tax_amount.toFixed(2)}</p>
//                     </div>
//                   </li>
//                 ))}
//               </ol>
//               <hr className="hr" />
//               <div className="subtotal_receipt">
//                 <p className="para_receipt">{t('Receipt.total')}</p>
//                 <p className="para_receipt">
//                   {(
//                     data.response.grand_total -
//                     data.response.loyalty_amount
//                   ).toFixed(2)}
//                 </p>
//               </div>
//             </div>
//             <hr className="hr" />
//             <div>
//               <p className="para_receipt">{t('Receipt.thank_you')}</p>
//               <p className="para_receipt">STEVESMUSIC</p>
//             </div>
//             <hr className="hr" />
//             <p className="area_receipt">{t('Receipt.promo_area')}</p>
//           </section>
//         </section>
//       )}
//     </>
//   );
// };

// export default Receipt;

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Menu,
  notification,
  Modal,
  AutoComplete,
  Input,
  Tooltip,
} from "antd";
import {
  createSalesOrder,
  fetchCustomers,
  getCouponCodeList,
  getCustomerDetail,
  getGuestCustomer,
  validatePromoCode,
  login,
  fetchtaxsAPI,
} from "../modules/LandingPage";
import DeleteImg from "../../src/assets/images/Delete.png";
import IconCash from "../../src/assets/images/cash.png";
import IconCard from "../../src/assets/images/card.png";
import ButtonTick from "../../src/assets/images/btn-tick.png";
import ButtonCross from "../../src/assets/images/btn-cross.png";
import EmptyCart from "../../src/assets/images/empty-cart.png";
import CashPaymentPopup from "./CashPaymentPopup";
import { CartContext } from "../common/CartContext";
import AddCustomerForm from "./Addcustomer";
import PromoCodePopup from "./PromoCodePopup";
// import ReactDOM from 'react-dom';
// import LoginPopup from "./LoginPopUp";
// import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Input, Button, Tooltip, Modal, notification } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { lowerCase } from "lodash";
const LoginPopup = ({ onClose, selectedCustomer, indicator }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [islogin, setIsLogin] = useState(false);

  // console.log(indicator, "indicatoe at 296 Login")
  // console.log(islogin, "is Login at line 298 at LoginPopUp")

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/main");
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      const data = await login(email, password);

      if (data.message && data.message.success_key === 1) {
        setIsLogin(true);
        console.log("Loggin Successfull");
        indicator(true);
        const user = {
          name: data.message.username,
          email: data.message.email,
          role: data.message.role,
          profileImage: data.message.profile_image,
        };

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("sid", data.message.sid);
        localStorage.setItem("api_key", data.message.api_key);
        localStorage.setItem("api_secret", data.message.api_secret);

        notification.success({
          message: "Authorization Successful",
          description: "AuthorizationDone Successfully.",
          placement: "topRight",
        });

        navigate("/main", { state: { loginResponse: data } });

        localStorage.removeItem("sid");
        localStorage.removeItem("api_key");
        localStorage.removeItem("api_secret");

        onClose(selectedCustomer, indicator);
      } else {
        setIsLogin(false);
        alert("Authorization failed. Please check your credentials.");
        indicator(false);
      }
    } catch (error) {
      setIsLogin(false);
      console.error("Login error:", error);
      alert("An error occurred during Authorization. Please try again.");
      indicator(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setTimeout(() => {
      setShowPassword(false);
    }, 3000);
  };

  return (
    <Modal
      title="Authorization Required"
      visible={true}
      onOk={() => {
        indicator(true);
        onClose();
      }}
      onCancel={() => {
        indicator(false);
        onClose(indicator);
      }}
      centered
      footer={null}
      width={400}
    >
      <div className="login-screen-unique">
        <form className="login-form-unique">
          <div className="form-group-unique">
            <Input
              id="email-unique"
              type="text"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group-unique">
            <Input
              id="password-unique"
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              suffix={
                <Tooltip
                  title={showPassword ? "Hide Password" : "Show Password"}
                >
                  {showPassword ? (
                    <EyeInvisibleOutlined
                      className="eye-icon-unique"
                      onClick={handleTogglePasswordVisibility}
                    />
                  ) : (
                    <EyeOutlined
                      className="eye-icon-unique"
                      onClick={handleTogglePasswordVisibility}
                    />
                  )}
                </Tooltip>
              }
            />
          </div>
          <Button
            type="primary"
            onClick={handleLogin}
            className="ant-btn-primary-unique"
          >
            Authorize
          </Button>
        </form>
      </div>
    </Modal>
  );
};
const Cart = ({ onPlaceOrder }) => {
  const [selectedTab, setSelectedTab] = useState("Takeaway");
  const [customers, setCustomers] = useState([]);
  const [guestCustomer, setGuestCustomer] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [cashReceived, setCashReceived] = useState(0);
  const [customerSelected, setCustomerSelected] = useState(false);
  const [customerSelectedDetails, setCustomerSelectedDetails] = useState([]);
  const [showAddCustomerForm, setShowAddCustomerForm] = useState(false);

  const {
    cartItems,
    removeItemFromCart,
    resetCart,
    completeOrder,
    setCartItems,
  } = useContext(CartContext);
  const [isPromoCodePopupVisible, setIsPromoCodePopupVisible] = useState(false);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [giftCardDiscount, setGiftCardDiscount] = useState(0);
  const [redeem, setRedeem] = useState(0);
  const [loyaltyProgram, setLoyaltyProgram] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [couponCodes, setCouponCodes] = useState([]);
  const [validatedPromoCode, setValidatedPromoCode] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [loyaltyInput, setLoyaltyInput] = useState("");
  const [loyaltyAmount, setLoyaltyAmount] = useState(0);
  const [loyaltyRedemptionAccount, setLoyaltyRedemptionAccount] = useState("");
  const [giftCard, setGiftCard] = useState("");
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
  const [isGiftCardValid, setIsGiftCardValid] = useState(false);
  const [isLoyaltyPointsValid, setIsLoyaltyPointsValid] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [editedPrices, setEditedPrices] = useState({});
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authPendingItem, setAuthPendingItem] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [editPriceDiscount, setEditpriceDiscount] = useState(0);
  const [storedPassword, setStoredPassword] = useState("");
  const [lastAuthorizedPrices, setLastAuthorizedPrices] = useState({});
  // const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandtaxes, setIsExpandtaxes] = useState(true);
  // const [price_of_product, setPriceOfProduct] = useState(1);
  const [checkedTaxes, setCheckedTaxes] = useState({});
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const [isLoginForCash, setIsLoginForCash] = useState(false);
  const [LoginCloseIndicator, setLoginCloseIndicator] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [pendingPriceChange, setPendingPriceChange] = useState(null);
  const [getTaxApi, setgetTaxApi] = useState({});
  const [totalofTotal, setTotalofTotal] = useState();

  // console.log("selectedPaymentMethod",selectedPaymentMethod);

  useEffect(() => {
    const storedPromoCode = localStorage.getItem("promoCode");
    const storedDiscount = localStorage.getItem("couponDiscount");
    const storedEmail = JSON.parse(localStorage.getItem("user"));

    if (storedEmail && storedEmail.email) {
      setEmail(storedEmail.email);
    } else {
      console.error("Email not found in local storage.");
      setEmail("");
    }

    if (storedPromoCode) {
      setPromoCode(storedPromoCode);
      setValidatedPromoCode(`${storedPromoCode}`);
    }

    if (storedDiscount) {
      setCouponDiscount(parseFloat(storedDiscount));
    }
  }, []);

  const handleApplyPromoCode = async (code) => {
    console.log(`Applying promo code: ${code}`);
    const validationResponse = await validatePromoCode(code);


    if (validationResponse && validationResponse.status === "valid") {
      if (validationResponse.coupon.pricing_rule.discount_percentage > 0) {


        const discountPercentage =
          validationResponse.coupon.pricing_rule.discount_percentage;
        let discountValue = 0;
        if (validationResponse.coupon.pricing_rule.apply_discount_on.toLowerCase() == "net total") {
          discountValue = (subtotal * discountPercentage) / 100;
          // console.log("Validation Response -> ", validationResponse.coupon.pricing_rule.apply_discount_on);
          // console.log("Grand Total  -> ", grandTotal);
          // console.log("TotalofTotal -> ", totalofTotal);
          setCouponDiscount(discountValue);
          setValidatedPromoCode(code);
          setPromoCode(code);
          localStorage.setItem("promoCode", code);
          localStorage.setItem("couponDiscount", discountValue);
          notification.success({
            message: "Promo Code Applied",
            description: `Promo code ${code} applied successfully!`,
          });
        }
        else if (validationResponse.coupon.pricing_rule.apply_discount_on.toLowerCase() == "grand total") {
          // discountValue = (grandTotal * discountPercentage) / 100;
          notification.error({
            message: "Invalid Promo Code",
            description: `Promo code ${code} is not valid as its on Grand Total.`,
          });
        }
        // console.log("Validation Response -> ",validationResponse.coupon.pricing_rule.apply_discount_on);
        // console.log("Grand Total  -> ",grandTotal );
        // console.log("TotalofTotal -> ",totalofTotal);
        // setCouponDiscount(discountValue);
        // setValidatedPromoCode(code);
        // setPromoCode(code);
        // localStorage.setItem("promoCode", code);
        // localStorage.setItem("couponDiscount", discountValue);
        // notification.success({
        //   message: "Promo Code Applied",
        //   description: `Promo code ${code} applied successfully!`,
        // });
      } else {
        const discountValue =
          validationResponse.coupon.pricing_rule.discount_amount;
        setCouponDiscount(discountValue);
        setValidatedPromoCode(code);
        setPromoCode(code);
        localStorage.setItem("promoCode", code);
        localStorage.setItem("couponDiscount", discountValue);
        notification.success({
          message: "Promo Code Applied",
          description: `Promo code ${code} applied successfully!`,
        });
      }
    } else {
      notification.error({
        message: "Invalid Promo Code",
        description: `Promo code ${code} is not valid.`,
      });
    }
  };
  const handleRemovePromoCode = () => {
    setValidatedPromoCode("");
    setCouponDiscount(0);
    setPromoCode("");
    setIsPromoCodeValid(false);
    localStorage.removeItem("promoCode");
    localStorage.removeItem("couponDiscount");
    notification.info({
      message: "Promo Code Removed",
      description: `Promo code has been removed.`,
    });
  };
  const handleSetCouponCodes = (codes) => {
    setCouponCodes(codes);
  };

  const calculateSubtotal = (items) => {
    if (!Array.isArray(items)) {
      console.error("Invalid items array:", items);
      return 0;
    }
    return items.reduce(
      (sum, item) => sum + item.product_price * item.quantity,
      0
    );
  };
  const [subtotal, setSubtotal] = useState(calculateSubtotal(cartItems));
  const discount = 0;
  // const taxRate = 0.1;
  const taxRate = 0;
  // const total = subtotal - couponDiscount + tax;

  const handleCashButtonClick = () => {
    localStorage.removeItem("cashTransaction");
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setCashReceived(0);
  };

  const handlePromoCodeClick = () => {
    if (promoCode) {
      setIsPromoCodeValid(true);
      setIsGiftCardValid(false);
    }
    setIsPromoCodePopupVisible(true);
  };
  const handlePromoPopupClose = () => {
    setIsPromoCodePopupVisible(false);
  };
  useEffect(() => {
    setSubtotal(calculateSubtotal(cartItems));
  }, [cartItems]);

  const customerOptions = filteredCustomers.map((customer) => ({
    value: `${customer.mobile_no || ""} | ${customer.customer_name || ""}`,
    label: (
      <div className="search-customer-list">
        <span className="search-cust-name">
          {customer.customer_name || "No Name"}
        </span>
        <span className="search-cust-number">
          {customer.mobile_no || "No Mobile Number"}
        </span>
      </div>
    ),
    customer: customer, // Include the customer object for onSelect
  }));

  const handleSearchChange = (value) => {
    setSearchTerm(value);

    if (value.trim() === "") {
      setFilteredCustomers(customers);
      setShowAddCustomerForm(false);
    } else {
      const filtered = customers.filter(
        (customer) =>
          customer.customer_name.toLowerCase().includes(value.toLowerCase()) ||
          customer.mobile_no.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredCustomers(filtered);
      setShowAddCustomerForm(filtered.length === 0);
      if (filtered.length === 0) {
        setSelectedCustomer(null);
        localStorage.removeItem("selectedCustomer");
      }
    }
  };
  // console.log(customerSelectedDetails, "ppppppp");

  const handleCustomerSelect = (customer) => {
    if (customer && customer.mobile_no) {
      setSelectedCustomer(customer);
      setSearchTerm(`${customer.mobile_no} | ${customer.customer_name}`);
      setIsInputFocused(false);
      setCustomerSelected(true);
      setShowAddCustomerForm(false);
      localStorage.setItem("selectedCustomer", JSON.stringify(customer));
    } else {
      setShowAddCustomerForm(true);
    }
  };

  const loadCustomers = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await fetchCustomers();
      if (data) {
        setCustomers(data);
        setFilteredCustomers(data);
        localStorage.setItem("customers", JSON.stringify(data));
      } else {
        setCustomers([]);
        setFilteredCustomers([]);
        setError("No Customer Found");
      }
    } catch (err) {
      setCustomers([]);
      setFilteredCustomers([]);
      setError("Failed to fetch customers");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCustomers();

    const handleStorageChange = () => {
      const savedCustomer = localStorage.getItem("selectedCustomer");
      if (savedCustomer) {
        const customer = JSON.parse(savedCustomer);
        setSelectedCustomer(customer);
        setSearchTerm(`${customer.mobile_no} | ${customer.customer_name}`);
        setCustomerSelected(true);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Initial check
    handleStorageChange();

    // Cleanup
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (customerSelected && selectedCustomer) {
      getCustomerDetails(selectedCustomer.name);
    }
  }, [customerSelected, selectedCustomer]);

  const handleSetLoyaltyProgram = (res) => {
    if (
      res.data &&
      res.data.message &&
      Array.isArray(res.data.message.customer)
    ) {
      const customer = res.data.message.customer[0];
      if (customer && customer.loyalty_program) {
        setLoyaltyProgram(customer.loyalty_program);
      }
    } else {
      setLoyaltyProgram(null);
    }
  };

  const getCustomerDetails = async (customerName) => {
    try {
      const res = await getCustomerDetail(customerName);
      if (res.status === 200) {
        setCustomerSelectedDetails(res.data.message);
        handleSetLoyaltyProgram(res);
      } else {
        console.log("error fetching details of customer");
      }
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };

  // {customerSelectedDetails.customer.map((customer, index) => (
  //   loyalProgram = customer.loyalty_program,
  // ))}

  useEffect(() => {
    if (searchTerm) {
      const filtered = customers.filter(
        (customer) =>
          customer.customer_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          customer.mobile_no.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCustomers(filtered);
      setShowAddCustomerForm(filtered.length === 0 && !selectedCustomer);
    } else {
      setFilteredCustomers(customers);
      setShowAddCustomerForm(false);
    }
  }, [searchTerm, customers, selectedCustomer]);

  const handleAddCustomer = (newCustomer) => {
    setCustomers([...customers, newCustomer]);
    setSelectedCustomer(newCustomer);
    setSearchTerm(`${newCustomer.mobile_no} | ${newCustomer.customer_name}`);
    localStorage.setItem("selectedCustomer", JSON.stringify(newCustomer));
    setShowAddCustomerForm(false); // Remove form is hidden after adding customer
  };

  const customerMenu = (
    <Menu>
      {filteredCustomers.length > 0 ? (
        filteredCustomers.map((customer) => (
          <Menu.Item
            key={customer.mobile_no}
            onClick={() => handleCustomerSelect(customer)}
          >
            {customer.mobile_no} | {customer.customer_name}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No Data Found</Menu.Item>
      )}
      {/* Conditionally render AddCustomerForm only if no customer is selected */}
      {!selectedCustomer && showAddCustomerForm && (
        <Menu.Item key="addCustomer">
          <AddCustomerForm
            searchTerm={searchTerm}
            onAddCustomer={handleAddCustomer}
          />
        </Menu.Item>
      )}
    </Menu>
  );

  const handleIncrement = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].quantity += 1;
    setCartItems(updatedCartItems);
  };

  const handleDecrement = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity -= 1;
      setCartItems(updatedCartItems);
    }
  };

  const handleRemoveFromCart = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems([]);
    setValidatedPromoCode("");
    setCouponDiscount(0);
    setPromoCode("");
    setIsPromoCodeValid(false);
    handleRemoveLoyaltyPoints();
    localStorage.removeItem("promoCode");
    localStorage.removeItem("couponDiscount");
    localStorage.removeItem("originalPrices");
    setCartItems(updatedCartItems);
    const itemId = cartItems[index].id;
    removeItemFromCart(itemId);
  };

  const handleEmptyCart = () => {
    setCartItems([]);
    setSelectedCustomer(null);
    setSearchTerm("");
    setValidatedPromoCode("");
    setCouponDiscount(0);
    setPromoCode("");
    setIsPromoCodeValid(false);
    handleRemoveLoyaltyPoints();
    localStorage.removeItem("promoCode");
    localStorage.removeItem("couponDiscount");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("originalPrices");
    resetCart();
  };

  const handleParkOrder = () => {
    if (!selectedCustomer) {
      Modal.error({
        title: "Attention!",
        content: "Please select a customer before parking the order.",
        className: "customer-error-modal",
      });
      return;
    }

    let parkedOrders = JSON.parse(localStorage.getItem("parkedOrders"));
    if (!Array.isArray(parkedOrders)) {
      parkedOrders = [];
    }
    const newParkedOrder = {
      name: new Date().getTime(),
      items: cartItems,
      subtotal: subtotal,
      total: grandTotal,
      customer: {
        customer_name: selectedCustomer.customer_name,
        mobile_no: selectedCustomer.mobile_no,
        name: selectedCustomer.name,
      },
      status: "cartItems",
      creation: new Date().toLocaleString(),
      contact_name: selectedCustomer.customer_name || "Guest",
      contact_mobile: selectedCustomer.mobile_no || "N/A",
    };

    parkedOrders.push(newParkedOrder);
    localStorage.setItem("parkedOrders", JSON.stringify(parkedOrders));

    setCartItems([]);
    localStorage.removeItem("cartItems");

    setSelectedCustomer(null);
    setSearchTerm("");
    localStorage.removeItem("selectedCustomer");
    localStorage.removeItem("CustomerDetails");

    Modal.success({
      message: "Success",
      content: "Order parked successfully.",
    });
  };

  // Function to format date to "YYYY-MM-DD HH:MM"
  const formatDateTime = (date) => {
    const pad = (n) => (n < 10 ? "0" + n : n);
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      " " +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds())
    );
  };

  // Function to format date to "YYYY-MM-DD"
  const formatDate = (date) => {
    const pad = (n) => (n < 10 ? "0" + n : n);
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate())
    );
  };
  // const subtotal = calculateSubtotal(cartItems);

  //   const toggletaxes = () => {
  //     setIsExpandtaxes(!isExpandtaxes);
  //   };

  //   const calculateTaxAmount = (
  //     taxPercentage,
  //     price_of_product,
  //     item_quantity
  //   ) => {
  //     const taxableAmount = subtotal - couponDiscount;
  //     const tax_amount = (taxableAmount * taxPercentage) / 100;
  //     return tax_amount;
  //   };
  //   // console.log("taxes ", cartItems.t)/
  //   // console.log("coupon Discount", couponDiscount);

  //   const aggregateTaxes = (cartItems) => {
  //     const taxData = {};
  //     const taxableSubtotal = subtotal - couponDiscount;

  //     cartItems.forEach((product) => {
  //       product.tax.forEach((taxItem) => {
  //         if (taxData[taxItem.tax_type]) {
  //           taxData[taxItem.tax_type] += calculateTaxAmount(
  //             taxItem.tax_rate,
  //             taxableSubtotal,
  //             1 // We're using the whole taxable subtotal, so quantity is 1
  //           );
  //         } else {
  //           taxData[taxItem.tax_type] = calculateTaxAmount(
  //             taxItem.tax_rate,
  //             taxableSubtotal,
  //             1 // We're using the whole taxable subtotal, so quantity is 1
  //           );
  //         }
  //       });
  //     });

  //     return taxData;
  //   };

  //   // const aggregatedTaxData = aggregateTaxes(cartItems);
  //   const aggregatedTaxData = getTaxApi; // Assuming getTaxApi contains the tax data in the required format

  //   useEffect(() => {
  //     // Initialize all taxes as checked by default
  //     const initialCheckedTaxes = {};
  //     Object.keys(aggregateTaxes(cartItems)).forEach((taxType) => {
  //       initialCheckedTaxes[taxType] = true;
  //     });
  //     setCheckedTaxes(initialCheckedTaxes);
  //   }, [cartItems]);

  //   const handleCheckboxChange = (taxType) => {
  //     setCheckedTaxes((prevState) => ({
  //       ...prevState,
  //       [taxType]: !prevState[taxType],
  //     }));
  //   };
  //   // console.log("checked Taxes", checkedTaxes);

  //   // const totalTaxAmount = Object.keys(aggregatedTaxData).reduce(
  //   //   (total, taxType) => {
  //   //     if (checkedTaxes[taxType]) {
  //   //       // return subtotal + aggregatedTaxData[taxType];
  //   //       return total + aggregatedTaxData[taxType];
  //   //     }
  //   //     return total;
  //   //   },
  //   //   0
  //   // );
  //   // const totalTaxAmount = aggregatedTaxData.reduce((total, taxItem) => {
  //   //   if (checkedTaxes[taxItem.description]) {
  //   //     const taxAmount = (subtotal - couponDiscount) * (taxItem.rate / 100);
  //   //     return total + taxAmount;
  //   //   }
  //   //   return total;
  //   // }, 0);
  //   console.log('getTaxApi:', getTaxApi); // Check what getTaxApi is

  // const totalTaxAmount = Array.isArray(getTaxApi)
  //   ? getTaxApi.reduce((total, taxItem) => {
  //       if (checkedTaxes[taxItem.description]) {
  //         const taxAmount = (subtotal - couponDiscount) * (taxItem.rate / 100);
  //         return total + taxAmount;
  //       }
  //       return total;
  //     }, 0)
  //   : 0; // If it's not an array, set totalTaxAmount to 0


  //   useEffect(() => {
  //     // Initialize all taxes as checked by default
  //     const initialCheckedTaxes = {};
  //     aggregatedTaxData.forEach((taxItem) => {
  //       initialCheckedTaxes[taxItem.description] = true;
  //     });
  //     setCheckedTaxes(initialCheckedTaxes);
  //   }, [aggregatedTaxData]);

  //   // console.log(totalTaxAmount, "Total of line 711 ");
  //   // console.log("total tax amount", totalTaxAmount);
  //   const tax = subtotal * taxRate;
  //   const grandTotal =
  //     subtotal -
  //     couponDiscount -
  //     loyaltyAmount -
  //     giftCardDiscount +
  //     totalTaxAmount;

  //   const placeOrder = async (customer) => {
  //     // console.log("Placing order for customer:", customer);
  //     if (!customer) {
  //       console.error("Cannot place order: No customer selected");
  //       return;
  //     }

  //     if (grandTotal < 0) {
  //       Modal.error({
  //         title: "Please add more items.",
  //         className: "success-modal",
  //       });
  //       return;
  //     }

  //     // console.log("Customer at line 696", customer);
  //     localStorage.setItem("cartcustomerselected", customer);

  //     const costCenter = localStorage.getItem("costCenter");

  //     const uncheckedTaxes = Object.keys(checkedTaxes).filter(
  //       (taxType) => !checkedTaxes[taxType]
  //     );
  //     // console.log(`customer at line 701 ${customer}`);

  //     if (uncheckedTaxes.length > 0) {
  //       setShowLoginPopup(true);
  //       setIsLoginForCash(true);
  //     } else {
  //       proceedWithOrder(customer);
  //       // console.log(`customer at line 720 ${customer}`);
  //     }
  //     // console.log(`customer at line 723 ${customer}`);
  //   };



  //   const calculateTotalTax = (taxData, subtotal) => {
  //     return taxData.reduce((total, taxItem) => {
  //       const taxAmount = (subtotal * taxItem.rate) / 100;
  //       return total + taxAmount;
  //     }, 0);
  //   };



  //   useEffect(() => {
  //     // Initialize all taxes as checked by default
  //     const initialCheckedTaxes = {};
  //     aggregatedTaxData.forEach((taxItem) => {
  //       initialCheckedTaxes[taxItem.description] = true;
  //     });
  //     setCheckedTaxes(initialCheckedTaxes);
  //   }, [aggregatedTaxData]);









  // Toggling taxes view
  const toggletaxes = () => {
    setIsExpandtaxes(!isExpandtaxes);
  };

  // Function to calculate the tax amount
  const calculateTaxAmount = (taxPercentage, taxableAmount) => {
    return (taxableAmount * taxPercentage) / 100;
  };

  // Aggregating taxes based on API data or cart items
  const aggregateTaxes = (cartItems) => {
    const taxData = {};
    const taxableSubtotal = subtotal - couponDiscount;

    cartItems.forEach((product) => {
      product.tax.forEach((taxItem) => {
        if (taxData[taxItem.tax_type]) {
          taxData[taxItem.tax_type] += calculateTaxAmount(
            taxItem.tax_rate,
            taxableSubtotal
          );
        } else {
          taxData[taxItem.tax_type] = calculateTaxAmount(
            taxItem.tax_rate,
            taxableSubtotal
          );
        }
      });
    });

    return taxData;
  };

  // Assuming getTaxApi contains the tax data in the required format
  const aggregatedTaxData = Array.isArray(getTaxApi) ? getTaxApi : [];

  // Effect to initialize checked taxes
  useEffect(() => {
    const initialCheckedTaxes = {};
    aggregatedTaxData.forEach((taxItem) => {
      initialCheckedTaxes[taxItem.description] = true;
    });
    setCheckedTaxes(initialCheckedTaxes);
  }, [aggregatedTaxData]);

  // Handle checkbox change for taxes
  const handleCheckboxChange = (taxType) => {
    setCheckedTaxes((prevState) => ({
      ...prevState,
      [taxType]: !prevState[taxType],
    }));
  };

  // Calculate the total tax amount
  const totalTaxAmount = aggregatedTaxData.reduce((total, taxItem) => {
    if (checkedTaxes[taxItem.description]) {
      const taxAmount = (subtotal - couponDiscount) * (taxItem.rate / 100);
      return total + taxAmount;
    }
    return total;
  }, 0);

  // Calculate the grand total
  const grandTotal =
    subtotal -
    couponDiscount -
    loyaltyAmount +
    totalTaxAmount;



  useEffect(() => {
    // Update state only under certain conditions
    setTotalofTotal(grandTotal);
    // if (grandTotal != grandTotal) {
    //   setTotalofTotal(grandTotal);
    // }
  }, [grandTotal]);


  // Function to place order
  const placeOrder = async (customer) => {
    if (!customer) {
      console.error("Cannot place order: No customer selected");
      return;
    }

    if (grandTotal < 0) {
      Modal.error({
        title: "Please add more items.",
        className: "success-modal",
      });
      return;
    }

    localStorage.setItem("cartcustomerselected", customer);
    const uncheckedTaxes = Object.keys(checkedTaxes).filter(
      (taxType) => !checkedTaxes[taxType]
    );

    if (uncheckedTaxes.length > 0) {
      setShowLoginPopup(true);
      setIsLoginForCash(true);
    } else {
      proceedWithOrder(customer);
    }
  };

  // Utility function to calculate total tax for given tax data and subtotal
  const calculateTotalTax = (taxData, subtotal) => {
    return taxData.reduce((total, taxItem) => {
      const taxAmount = (subtotal * taxItem.rate) / 100;
      return total + taxAmount;
    }, 0);
  };

  // Effect to initialize checked taxes
  useEffect(() => {
    const initialCheckedTaxes = {};
    aggregatedTaxData.forEach((taxItem) => {
      initialCheckedTaxes[taxItem.description] = true;
    });
    setCheckedTaxes(initialCheckedTaxes);
  }, [aggregatedTaxData]);
  // const customer = localStorage.getItem('customer', customer);
  // const customer = localStorage.getItem("selectedCustomer");

  // const handleLoginPopupClose = (selectedCustomer, indicator) => {
  //   setShowLoginPopup(false);
  //   console.log("Indicator at handleLoginPopupClose:", indicator);
  //   setLoginCloseIndicator(indicator);

  //   if (indicator) {
  //     console.log("Login successful, proceeding with order");
  //     proceedWithOrder(selectedCustomer);
  //   } else {
  //     console.log("Login cancelled or failed");
  //   }
  // };
  // console.log("Item stock Check", item);
  const handleLoginPopupClose = (selectedCustomer, indicator) => {
    setShowLoginPopup(false);
    console.log("Indicator at handleLoginPopupClose:", indicator);
    setLoginCloseIndicator(indicator);

    if (indicator && isLoginForCash) {
      console.log("Login successful, proceeding with order");
      proceedWithOrder(selectedCustomer);
      setIsLoginForCash(false);
      // indicator(false);
    } else if (indicator) {
      console.log("Login successful, applying price change");
      applyPriceChangeAfterLogin();
    } else {
      console.log("Login cancelled or failed");
      // Reset the edited price if login failed
      if (pendingPriceChange) {
        setEditedPrices((prev) => {
          const newPrices = { ...prev };
          delete newPrices[pendingPriceChange.itemId];
          return newPrices;
        });
      }
    }
    setPendingPriceChange(null);
  };

  const isPriceEditingDisabled = () => {
    return validatedPromoCode || isLoyaltyPointsValid;
  };
  // const handlePrint = (orderDetails) => {
  //   const data = encodeURIComponent(JSON.stringify(orderDetails));
  //   window.open(`/currentreceipt?data=${data}`, '_blank');
  //   // refetchData((prev) => !prev)
  //   Modal.destroyAll()
  // }
  // const handlePrint=(orderDetails)=>{
  //   const url = '/currentreceipt';
  //   const state = { response: orderDetails };
  //   const encodedState = encodeURIComponent(JSON.stringify(state));
  //   window.open(`${url}?state=${encodedState}`, '_blank');
  // }
  const handlePrint = (orderDetails) => {
    const url = '/currentreceipt';
    // Store data in local storage
    localStorage.setItem('orderDetails', JSON.stringify(orderDetails));
    console.log("Receipt details", orderDetails);

    // Open the new tab
    window.open(url, '_blank');
  };



  const costcenter = localStorage.getItem("costCenter");
  useEffect(() => {
    fetchtaxsAPI(costcenter).then((response) => setgetTaxApi(response));
  }, [costcenter]);

  // setTotalofTotal(grandTotal);

  const proceedWithOrder = async (customer) => {
    // console.log(`proceedwithorder 734 ${customer}`);
    if (grandTotal < 0) {
      Modal.error({
        title: "Please add more items.",
        className: "success-modal",
      });
    }
    const savedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    // console.log("saved customer", savedCustomer);

    if (!savedCustomer || cartItems.length === 0) return;

    const now = new Date();
    const transactionDate = formatDateTime(now);
    const deliveryDate = formatDate(
      new Date(now.getTime() + 24 * 60 * 60 * 1000)
    );
    const openingShiftResponse = JSON.parse(
      localStorage.getItem("openingShiftResponse")
    );
    const user = JSON.parse(localStorage.getItem("user"));
    const costCenter = localStorage.getItem("costCenter");

    const tax = checkedTaxes;

    const orderDetails = {
      pos_profile: openingShiftResponse?.message?.pos_profile?.name || "",
      pos_opening_shift:
        openingShiftResponse?.message?.pos_opening_shift?.name || "",
      hub_manager: user.email,
      customer:savedCustomer.name || savedCustomer.customer_name || savedCustomer,
      customer_name: savedCustomer.customer_name,
      transaction_date: transactionDate,
      delivery_date: deliveryDate,
      items: cartItems.map((item) => ({
        item_code: item.id,
        item_name: item.name,
        rate: item.product_price,
        sub_items: [],
        qty: item.quantity,
        ordered_price: item.product_price * item.quantity,
        tax: [],
        // tax: item.tax
        //   .filter((taxItem) => tax[taxItem.tax_type] === true)
        //   .map((taxItem) => ({
        //     item_tax_template: taxItem.item_tax_template,
        //     tax_type: taxItem.tax_type,
        //     tax_rate: taxItem.tax_rate,
        //     custom_cost_center: taxItem.custom_cost_center,
        //     cost_center: taxItem.cost_center,
        //   })),
        // estimated_time: 30,
      })),
      mode_of_payment: selectedPaymentMethod,
      mpesa_No: "",
      // tax: getTaxApi, // This can be the overall tax if applicable
      tax: getTaxApi.filter((taxItem) => checkedTaxes[taxItem.description]), // This can be the overall tax if applicable
      cost_center: costCenter,
      source: "POS",
      coupon_code: promoCode || giftCard,
      grand_total: grandTotal,
      redeem_loyalty_points: redeem,
      loyalty_points: loyaltyInput,
      loyalty_amount: loyaltyAmount,
      loyalty_program: loyaltyProgram,
      loyalty_redemption_account: loyaltyRedemptionAccount,
    };

    try {
      const res = await createSalesOrder(orderDetails);

      if (res && res.message && res.message.success_key === 1) {
        // console.log("Sales order created", res.message.success_key);
        setLoginCloseIndicator(false);
        localStorage.removeItem("selectedCustomer"); // Clear from localStorage
        Modal.success({
          title: "Congratulations!",
          content: (
            <>
              <div>
                Order placed successfully.
                {/* <br />
                <strong>to {customer.customer_name || customer}</strong> */}
              </div>
            </>
          ),
          className: "success-modal",
          footer: (
            <Button
              className="print-button-for-receipt" 
              onClick={() =>
                handlePrint({
                  ...orderDetails,
                  res,
                  hub_manager: localStorage.getItem("user")
                    ? JSON.parse(localStorage.getItem("user"))
                    : null,
                })
              }
            >
              Print Receipt
            </Button>
          ),
          // Ensures the close (cross) button is present in the top-right corner
          closable: true,
        });
                  
        // Modal.success({
        //   title: "Congratulations!",
        //   content:
        //     (console.log("order created successfully", res.message.success_key),
        //       (
        //         <>
        //           <div>
        //             Order placed successfully.
                   
        //             {/* <br />
        //         <strong>to {customer.customer_name || customer}</strong> */}
        //           </div>
        //           <Button  className=" print-button-for-receipt" onClick={() => handlePrint({ ...orderDetails, res, hub_manager: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null })} >Print</Button>

        //         </>
        //       )),
        //   className: "success-modal",
        // });

        setCartItems([]);
        setSelectedCustomer(null);
        setSearchTerm("");
        setCouponCodes("");
        setCouponDiscount("");
        setValidatedPromoCode("");
        localStorage.removeItem("couponDiscount");
        localStorage.removeItem("promoCode");
        setRedeem(0);
        setCustomerSelectedDetails([]);
        setLoyaltyAmount(0);
        setLoyaltyInput("");
        localStorage.removeItem("GiftCardDiscount");
        setGiftCardDiscount("");
        setGiftCard("");
        localStorage.removeItem("cashTransaction");
      } else {
        console.log(`Error placing order: ${error.message}`);
        notification.error({
          message: "Error",
          description: `Unexpected response format`,
        });
        setCouponCodes("");
        setCouponDiscount("");
        setValidatedPromoCode("");
        localStorage.removeItem("couponDiscount");
        localStorage.removeItem("promoCode");
        grandTotal = 0;
      }
    } catch (error) {
      console.log(`Error placing order: ${error.message}`);
      notification.error({
        message: "Error",
        description: `Error placing order: ${error.message}`,
      });
    }
    // ... (rest of the original placeOrder function logic)
  };

  const handleSelectPaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
  };

  //  console.log("Tax Api -> ", getTaxApi);
  const handlePlaceOrder = async () => {
    if (!selectedCustomer) {
      Modal.error({
        title: "Attention!",
        content: "Please select a customer before placing the order.",
        className: "customer-error-modal",
      });
      return;
    }

    if (!selectedPaymentMethod) {
      notification.error({
        message: "Payment Method Required",
        description: "Please select a payment method before placing the order.",
      });
      return;
    }

    await placeOrder(selectedCustomer);
    setSearchTerm(""); // Clear search term only after the order is placed
    setSelectedCustomer(null);
    setSelectedPaymentMethod(); // Clear selected customer state
    // localStorage.removeItem("selectedCustomer"); // Clear from localStorage
  };

  const handleGetGuestCustomer = async () => {
    try {
      const res = await getGuestCustomer();
      if (res.status === 200) {
        const guestCustomer = res.data.message.data.guest_customer;
        setLoyaltyRedemptionAccount(
          res.data.message.data.loyalty_redemption_account
        );
        // console.log(res.data.message.data.loyalty_redemption_account)
        setEditpriceDiscount(res.data.message.data.edit_price_discount);
        setGuestCustomer(guestCustomer);
        // console.log("Guest customer", guestCustomer);
      } else {
        console.log("Error in getting the Guest Customer");
      }
    } catch (error) {
      console.log("Error in fetching guest customer:", error.message);
    }
  };

  useEffect(() => {
    handleGetGuestCustomer();
  }, []);
  const handleQuickOrder = () => {
    if (!guestCustomer) {
      Modal.error({
        title: "Guest Customer Not Found",
        content: "Please wait while we fetch the guest customer information.",
      });
      return;
    }

    // Find matching customer in local storage
    const matchedCustomer = customers.find(
      (customer) => guestCustomer === customer.name
    );

    if (matchedCustomer) {
      // Set matched customer as selectedCustomer and update local storage
      setSelectedCustomer(matchedCustomer);
      localStorage.setItem("selectedCustomer", JSON.stringify(matchedCustomer));

      setSearchTerm(
        `${matchedCustomer.mobile_no} | ${matchedCustomer.customer_name}`
      );
      setCustomerSelected(true);
    } else {
      Modal.warning({
        title: "Customer Not Found",
        content: "The guest customer does not match any existing customer.",
      });
      setSearchTerm(""); // Clear search term if no match found
      setSelectedCustomer(null);
      setCustomerSelected(false);
      localStorage.removeItem("selectedCustomer"); // Remove from local storage if no match found
    }
  };

  // const handleUnitPriceChange = (index, newPrice) => {
  //   const item = cartItems[index];
  //   if (item) {
  //     setEditedPrices((prev) => ({ ...prev, [item.id]: newPrice }));
  //   }
  // };
  // const handleUnitPriceChange = (itemId, newPrice) => {
  //   setEditedPrices(prev => ({ ...prev, [itemId]: newPrice }));
  //   const newPrice = newValue === '' ? '' : parseFloat(newValue);
  //   setEditedPrices(prev => ({ ...prev, [itemId]: newPrice }));
  // };
  const handleUnitPriceChange = (itemId, newValue) => {
    // console.log("handle unit price change: ");
    if (isPriceEditingDisabled()) {
      notification.info({
        message: "Price Editing Disabled",
        description:
          "You can't edit prices while a promo code or loyalty points are applied. Please remove them to edit prices.",
      });

      return;
    }
    // Allow empty string or convert to number
    const newPrice = newValue === "" ? "" : parseFloat(newValue);
    setEditedPrices((prev) => ({ ...prev, [itemId]: newPrice }));
  };

  // const handleSavePriceChange = (itemId) => {
  //   const newPrice = editedPrices[itemId];
  //   if (newPrice !== undefined) {
  //     const updatedCartItems = cartItems.map(item =>
  //       item.id === itemId ? { ...item, product_price: newPrice } : item
  //     );
  //     setCartItems(updatedCartItems);
  //     setEditedPrices(prev => {
  //       const newPrices = { ...prev };
  //       delete newPrices[itemId];
  //       return newPrices;
  //     });
  //   }
  //   setEditingItemId(null);
  // };
  // const handleSavePriceChange = (itemId) => {
  //   let newPrice = editedPrices[itemId];
  //   if (newPrice === '' || isNaN(newPrice)) {
  //     newPrice = 0;
  //   }
  //   const updatedCartItems = cartItems.map(item =>
  //     item.id === itemId ? { ...item, product_price: newPrice } : item
  //   );
  //   setCartItems(updatedCartItems);
  //   setEditedPrices(prev => {
  //     const newPrices = { ...prev };
  //     delete newPrices[itemId];
  //     return newPrices;
  //   });
  //   setEditingItemId(null);
  // };
  // const handleSavePriceChange = (itemId) => {
  //   let newPrice = editedPrices[itemId];
  //   if (newPrice === '' || isNaN(newPrice)) {
  //     newPrice = 0;
  //   }
  //   const updatedCartItems = cartItems.map(item =>
  //     item.id === itemId ? { ...item, product_price: newPrice } : item
  //   );
  //   setCartItems(updatedCartItems);
  //   setEditedPrices(prev => {
  //     const newPrices = { ...prev };
  //     delete newPrices[itemId];
  //     return newPrices;
  //   });
  //   setEditingItemId(null);
  // };
  const handleSavePriceChange = (itemId) => {
    if (isPriceEditingDisabled()) return;
    let newPrice = editedPrices[itemId];
    if (newPrice === "" || isNaN(newPrice)) {
      newPrice = 0;
    }
    setPendingPriceChange({ itemId, newPrice });
    setShowLoginPopup(true);
  };
  const applyPriceChangeAfterLogin = () => {
    if (pendingPriceChange) {
      const { itemId, newPrice } = pendingPriceChange;
      const updatedCartItems = cartItems.map((item) =>
        item.id === itemId ? { ...item, product_price: newPrice } : item
      );
      setCartItems(updatedCartItems);
      setEditedPrices((prev) => {
        const newPrices = { ...prev };
        delete newPrices[itemId];
        return newPrices;
      });
      setEditingItemId(null);
      setPendingPriceChange(null);
    }
  };

  const validatePriceChange = (index) => {
    const originalPrices = JSON.parse(localStorage.getItem("originalPrices"));
    const cartItem = cartItems[index];

    if (!originalPrices) {
      console.error("Original prices not found in localStorage.");
      return;
    }

    if (!cartItem) {
      console.error(`Cart item at index ${index} not found.`);
      return;
    }

    const originalPrice = originalPrices[cartItem.id];

    if (originalPrice === undefined) {
      console.error(`Original price for item ID ${cartItem.id} not found.`);
      return;
    }

    const newPrice = parseFloat(editedPrices[cartItem.id]);
    if (isNaN(newPrice)) {
      console.error(
        `Invalid price entered for item at index ${index}: ${editedPrices[cartItem.id]
        }`
      );
      return;
    }

    const minPrice = originalPrice * (1 - editPriceDiscount / 100);
    const maxPrice = originalPrice * (1 + editPriceDiscount / 100);

    if (newPrice < minPrice || newPrice > maxPrice) {
      setAuthPendingItem({ id: cartItem.id, index, newPrice, originalPrice });
      setAuthModalVisible(true);
    } else {
      const newCartItems = [...cartItems];
      newCartItems[index].product_price = newPrice;
      setCartItems(newCartItems);
      setEditedPrices((prev) => {
        const updatedPrices = { ...prev };
        delete updatedPrices[cartItem.id];
        return updatedPrices;
      });
    }
  };

  const handleAuth = async () => {
    try {
      if (!storedPassword) {
        const response = await login(email, password);
        if (response.message.success_key === 1) {
          setStoredPassword(password);
          updateCartWithNewPrice();
        } else {
          alert("Authorization failed");
          resetToOriginalPrice();
        }
      } else {
        if (password === storedPassword) {
          updateCartWithNewPrice();
        } else {
          alert("Authorization failed");
          resetToOriginalPrice();
        }
      }
    } catch (error) {
      console.error("Authorization error:", error);
      alert("Authorization error");
      resetToOriginalPrice();
    } finally {
      setAuthPendingItem(null);
      setAuthModalVisible(false);
      setPassword("");
    }
  };

  const updateCartWithNewPrice = () => {
    const newCartItems = [...cartItems];
    const { id, index, newPrice } = authPendingItem;
    newCartItems[index].product_price = newPrice;
    setCartItems(newCartItems);
    setLastAuthorizedPrices((prev) => ({ ...prev, [id]: newPrice }));
    setEditedPrices((prev) => {
      const updatedPrices = { ...prev };
      delete updatedPrices[id];
      return updatedPrices;
    });
  };

  const resetToOriginalPrice = () => {
    const { id, originalPrice } = authPendingItem;
    setEditedPrices((prev) => ({
      ...prev,
      [id]:
        lastAuthorizedPrices[id] !== undefined
          ? lastAuthorizedPrices[id]
          : originalPrice,
    }));
  };

  const handleAuthCancel = () => {
    resetToOriginalPrice();
    setAuthModalVisible(false);
    setPassword("");
  };

  // const renderCartItems = () => {
  //   if (cartItems.length === 0) {
  //     return (
  //       <div className="empty-cart">
  //         <img src={EmptyCart} alt="Empty Cart" />
  //         <h2>No items in the cart</h2>
  //       </div>
  //     );
  //   }

  //   return cartItems.map((item, index) => (
  //     <div key={index} className="cart-item">
  //       <span className="cart-item-name">{item.name}</span>
  //       <span className="cart-item-unitprice">
  //         <Input
  //           type="number"
  //           value={
  //             editedPrices[item.id] !== undefined
  //               ? editedPrices[item.id]
  //               : item.product_price
  //           }
  //           onChange={(e) =>
  //             handleUnitPriceChange(index, parseFloat(e.target.value))
  //           }
  //           onBlur={() => validatePriceChange(index)}
  //           className="unit-price-input"
  //           onKeyPress={(e) => {
  //             if (e.key === "Enter") {
  //               validatePriceChange(index);
  //             }
  //           }}
  //           step="any"
  //         />
  //       </span>
  //       <span className="cart-item-qty quantity-control">
  //         <Button onClick={() => handleDecrement(index)}>-</Button>
  //         <span>{item.quantity}</span>
  //         <Button onClick={() => handleIncrement(index)}>+</Button>
  //       </span>
  //       <span className="cart-item-totalprice">
  //         ${(item.product_price * item.quantity).toFixed(2)}
  //       </span>
  //       <span className="cart-item-action">
  //         <img
  //           src={DeleteImg}
  //           alt="Delete"
  //           onClick={() => handleRemoveFromCart(index)}
  //           style={{ cursor: "pointer", marginRight: "4px" }}
  //         />
  //       </span>
  //     </div>
  //   ));
  // };
  // const renderCartItems = () => {
  //   if (cartItems.length === 0) {
  //     return (
  //       <div className="empty-cart">
  //         <img src={EmptyCart} alt="Empty Cart" />
  //         <h2>No items in the cart</h2>
  //       </div>
  //     );
  //   }

  //   return cartItems.map((item, index) => (
  //     <div key={index} className="cart-item">
  //       <span className="cart-item-name">{item.name}</span>
  //       <span className="cart-item-unitprice">
  //         {editingItemId === item.id ? (
  //           <Input
  //             type="number"
  //             value={editedPrices[item.id] || item.product_price}
  //             onChange={(e) => handleUnitPriceChange(item.id, parseFloat(e.target.value))}
  //             onBlur={() => handleSavePriceChange(item.id)}
  //             onPressEnter={() => handleSavePriceChange(item.id)}
  //             autoFocus
  //           />
  //         ) : (
  //           <span onClick={() => setEditingItemId(item.id)}>
  //             ${item.product_price.toFixed(2)}
  //           </span>
  //         )}
  //       </span>
  //       <span className="cart-item-qty quantity-control">
  //         <Button onClick={() => handleDecrement(index)}>-</Button>
  //         <span>{item.quantity}</span>
  //         <Button onClick={() => handleIncrement(index)}>+</Button>
  //       </span>
  //       <span className="cart-item-totalprice">
  //         ${(item.product_price * item.quantity).toFixed(2)}
  //       </span>
  //       <span className="cart-item-action">
  //         <img
  //           src={DeleteImg}
  //           alt="Delete"
  //           onClick={() => handleRemoveFromCart(index)}
  //           style={{ cursor: "pointer", marginRight: "4px" }}
  //         />
  //       </span>
  //     </div>
  //   ));
  // };
  // const renderCartItems = () => {
  //   if (cartItems.length === 0) {
  //     return (
  //       <div className="empty-cart">
  //         <img src={EmptyCart} alt="Empty Cart" />
  //         <h2>No items in the cart</h2>
  //       </div>
  //     );
  //   }
   

  //   return cartItems.map((item, index) => (
  //     <div key={index} className="cart-item">
  //       <span className="cart-item-name">{item.name}</span>
  //       <span className="cart-item-unitprice">
  //         {editingItemId === item.id ? (
  //           // <Input
  //           //   type="number"
  //           //   value={editedPrices[item.id] !== undefined ? editedPrices[item.id] : item.product_price}
  //           //   onChange={(e) => handleUnitPriceChange(item.id, e.target.value)}
  //           //   onBlur={() => handleSavePriceChange(item.id)}
  //           //   onPressEnter={() => handleSavePriceChange(item.id)}
  //           //   autoFocus
  //           //   min="0"
  //           //   step="0.01"
  //           // />
  //           <Input
  //             type="number"
  //             value={
  //               editedPrices[item.id] !== undefined
  //                 ? editedPrices[item.id]
  //                 : item.product_price
  //             }
  //             onChange={(e) => handleUnitPriceChange(item.id, e.target.value)}
  //             onBlur={() => handleSavePriceChange(item.id)}
  //             onPressEnter={() => handleSavePriceChange(item.id)}
  //             autoFocus
  //             min="0"
  //             step="0.01"
  //             disabled={isPriceEditingDisabled()}
  //           />
  //         ) : (
  //           <span
  //             onClick={() =>
  //               !isPriceEditingDisabled() && setEditingItemId(item.id)
  //             }
  //           >
  //             $
  //             {editedPrices[item.id] !== undefined
  //               ? editedPrices[item.id]
  //               : item.product_price}
  //           </span>
  //         )}
  //       </span>
  //       <span className="cart-item-qty quantity-control">
  //         <Button onClick={() => handleDecrement(index)}>-</Button>
  //         <span>{item.quantity}</span>
  //         {/* <Button onClick={() => handleIncrement(index)}>+</Button> */}
  //         <Button
  //           onClick={() => handleIncrement(index)}
  //           disabled={item.stock_qty === item.quantity}
  //         >
  //           +
  //         </Button>
  //       </span>
  //       <span className="cart-item-totalprice">
  //         $
  //         {(
  //           (editedPrices[item.id] !== undefined
  //             ? editedPrices[item.id]
  //             : item.product_price) * item.quantity
  //         ).toFixed(2)}
  //       </span>
  //       <span className="cart-item-action">
  //         <img
  //           src={DeleteImg}
  //           alt="Delete"
  //           onClick={() => handleRemoveFromCart(index)}
  //           style={{ cursor: "pointer", marginRight: "4px" }}
  //         />
  //       </span>
  //     </div>
  //   ));
  // };
  const renderCartItems = () => {
    if (cartItems.length === 0) {
      return (
        <div className="empty-cart">
          <img src={EmptyCart} alt="Empty Cart" />
          <h2>No items in the cart</h2>
        </div>
      );
    }
  
    return cartItems.map((item, index) => {
      // console.log(item); // Log item data to the console
  
      return (
        <div key={index} className="cart-item">
          <span className="cart-item-name">{item.name}</span>
          <span className="cart-item-unitprice">
            {editingItemId === item.id ? (
              <Input
                type="number"
                value={
                  editedPrices[item.id] !== undefined
                    ? editedPrices[item.id]
                    : item.product_price
                }
                onChange={(e) => handleUnitPriceChange(item.id, e.target.value)}
                onBlur={() => handleSavePriceChange(item.id)}
                onPressEnter={() => handleSavePriceChange(item.id)}
                autoFocus
                min="0"
                step="0.01"
                disabled={isPriceEditingDisabled()}
              />
            ) : (
              <span
                onClick={() =>
                  !isPriceEditingDisabled() && setEditingItemId(item.id)
                }
              >
                $
                {editedPrices[item.id] !== undefined
                  ? editedPrices[item.id]
                  : item.product_price}
              </span>
            )}
          </span>
          <span className="cart-item-qty quantity-control">
            <Button onClick={() => handleDecrement(index)}>-</Button>
            <span>{item.quantity}</span>
            <Button
              onClick={() => handleIncrement(index)}
              disabled={item.quantity >= item.stock_qty}
            >
              +
            </Button>
          </span>
          <span className="cart-item-totalprice">
            $
            {(
              (editedPrices[item.id] !== undefined
                ? editedPrices[item.id]
                : item.product_price) * item.quantity
            ).toFixed(2)}
          </span>
          <span className="cart-item-action">
            <img
              src={DeleteImg}
              alt="Delete"
              onClick={() => handleRemoveFromCart(index)}
              style={{ cursor: "pointer", marginRight: "4px" }}
            />
          </span>
        </div>
      );
    });
  };
  

  

  const handleClearStorage = () => {
    // localStorage.clear();
    sessionStorage.clear();
    console.log("Local storage and session storage cleared");
  };

  useEffect(() => {
    handleClearStorage();
  }, []);

  const handleLoyaltyInputChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);
    if (value === "") {
      setLoyaltyInput("");
      setRedeem(0);
    } else if (!isNaN(parsedValue)) {
      if (parsedValue <= customerSelectedDetails.loyalty_points) {
        setLoyaltyInput(parsedValue);
        setRedeem(1);
      } else {
        notification.error({
          message: "Invalid Loyalty Points",
          description: `You cannot enter more than ${customerSelectedDetails.loyalty_points} loyalty points`,
        });
      }
    }
  };

  const handleRemoveLoyaltyPoints = () => {
    setLoyaltyInput("");
    setIsLoyaltyPointsValid(false);
    setLoyaltyAmount(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedPoints =
      loyaltyInput * customerSelectedDetails.conversion_factor;
    setLoyaltyAmount(appliedPoints);
    setIsLoyaltyPointsValid(true);
  };

  const handleRemoveGiftCard = () => {
    setGiftCard("");
    setIsGiftCardValid(false);
    setGiftCardDiscount(0);
    localStorage.removeItem("GiftCardDiscount");
    setGiftCardDiscount("");
    notification.info({
      message: "Gift card Removed",
      description: `Gift card has been removed.`,
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const isguestCustomer = selectedCustomer?.customer_name.toLowerCase() === "guest customer";

  const handleCreditClick = () => {
    if (validatedPromoCode || isGiftCardValid || isLoyaltyPointsValid) {
      Modal.warning({
        title: "Payment Method Restriction",
        content:
          "You cannot use credit with a promo code, gift card, or loyalty points applied.",
      });
      return;
    }

    const availableCredit =
      customerSelectedDetails.credit_limit -
      customerSelectedDetails.outstanding_amount;

    handleSelectPaymentMethod("Credit");

    if (customerSelectedDetails.credit_limit === 0) {
      Modal.warning({
        title: "Credit Limit Zero",
        content: (
          <div style={{ textAlign: "center" }}>
            <p>
              Credit Limit is Zero, please make a payment entry to increase the
              credit limit
            </p>
          </div>
        ),
      });
    } else if (
      customerSelectedDetails.outstanding_amount + grandTotal >
      customerSelectedDetails.credit_limit
    ) {
      Modal.warning({
        title: "Insufficient Credit Balance",
        content: (
          <div style={{ textAlign: "center" }}>
            <p>
              Insufficient Credit Balance, please make a payment entry to
              increase the credit limit
            </p>
            <h4>
              <strong>Available Credit: {availableCredit}</strong>
            </h4>
          </div>
        ),
      });
    } else {
      placeOrder(selectedCustomer);
    }
  };
  // console.log("Tax Api -> ", getTaxApi);
  // console.log("isGuestCustomer -> ",isguestCustomer); // This should print true or false


  return (
    <div className="cartItems">
      <div>
        <ul className="tab-header">
          <li
            className={selectedTab === "Takeaway" ? "active" : ""}
            onClick={() => setSelectedTab("Takeaway")}
          >
            Takeaway
          </li>
          {/* <li
            className={selectedTab === "Delivery" ? "active" : ""}
            onClick={() => setSelectedTab("Delivery")}
          >
            Delivery
          </li> */}
        </ul>
        <div className="tab-content">
          <div
            className={`tab-pane ${selectedTab === "Takeaway" ? "active" : ""}`}
          >
            <div className="quick-order">
              <div className="cart-search-cont">
                <div className="cart-search">
                  <AutoComplete
                    options={customerOptions}
                    onSelect={(value, option) =>
                      handleCustomerSelect(option.customer)
                    }
                    onSearch={handleSearchChange}
                    placeholder="Search by Customer Name/Number"
                    value={searchTerm}
                    onChange={(value) => setSearchTerm(value)} // Ensure onChange is added to update searchTerm
                    onFocus={() => setIsInputFocused(true)}
                  />

                  {loading && <Spin />}
                  {filteredCustomers.length === 0 &&
                    searchTerm &&
                    showAddCustomerForm && (
                      <AddCustomerForm
                        searchTerm={searchTerm}
                        onAddCustomer={handleAddCustomer}
                      />
                    )}
                </div>
              </div>
              <Button className="quick-order-btn" onClick={handleQuickOrder}>
                Quick Order
              </Button>
            </div>
            <div className="cart-header d-flex justify-content-between">
              <div className="cart-head-left">
                <h3>Cart</h3>
              </div>
              <div className="cart-head-right">
                <button
                  onClick={handleEmptyCart}
                  disabled={cartItems.length === 0}
                >
                  Reset Order
                </button>
                <button
                  onClick={handleParkOrder}
                  disabled={cartItems.length === 0}
                >
                  Park
                </button>
              </div>
            </div>
            <div className="cart-body">
              {cartItems.length === 0 ? (
                <div className="empty-cart">
                  <img src={EmptyCart} alt="Empty Cart" />
                </div>
              ) : (
                <>
                  <div className="cart-item-cont">
                    <div className="cart-item cart-item-head">
                      <span className="cart-item-name">Product Name</span>
                      <span className="cart-item-unitprice">Unit Price</span>
                      <span className="cart-item-qty">Qty</span>
                      <span className="cart-item-totalprice">Total</span>
                      <span className="cart-item-action">&nbsp;</span>
                    </div>
                    {renderCartItems()}
                  </div>
                  <div className="cart-sub-total">
                    <span>Subtotal</span>
                    <span>${subtotal.toFixed(2)}</span>
                  </div>
                  <div className="promo-header" onClick={toggleExpand}>
                    <span>{isExpanded ? "▼" : "►"} Promotion</span>
                  </div>
                  {isExpanded && (
                    <div className="promocode">
                      <div>
                        <label>Enter Promo Code</label>
                        <span className="promo-input-wrap">
                          <input
                            type="text"
                            placeholder=" "
                            value={
                              validatedPromoCode
                                ? `${validatedPromoCode} Applied`
                                : ""
                            }
                            onClick={handlePromoCodeClick}
                            readOnly
                            disabled={isGiftCardValid}
                          />
                          {validatedPromoCode ? (
                            <Button
                              type="submit"
                              onClick={handleRemovePromoCode}
                            >
                              <img src={ButtonCross} alt="Remove Promo Code" />
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              onClick={handlePromoCodeClick}
                            >
                              <img src={ButtonTick} alt="Apply Promo Code" />
                            </Button>
                          )}
                        </span>
                      </div>

                      {!isguestCustomer && (
                        <>
                          <div>
                            <label>
                              Loyalty Points (
                              {customerSelectedDetails.loyalty_points})
                            </label>
                            <span className="promo-input-wrap">
                              <input
                                type="text"
                                placeholder=""
                                value={loyaltyInput}
                                onChange={handleLoyaltyInputChange}
                                max={customerSelectedDetails.loyalty_points}
                              />
                              {isLoyaltyPointsValid ? (
                                <Button
                                  type="button"
                                  onClick={handleRemoveLoyaltyPoints}
                                >
                                  <img
                                    src={ButtonCross}
                                    alt="Remove Loyalty Points"
                                  />
                                </Button>
                              ) : (
                                <Button type="button" onClick={handleSubmit}>
                                  <img src={ButtonTick} alt="" />
                                </Button>
                              )}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                  {/* ------------------------------------------------------------------- */}

                  <div className="taxes-header" onClick={toggletaxes}>
                    <span className="taxes-span-header">
                      {isExpandtaxes ? "▼" : "►"} Taxes
                    </span>
                  </div>
                  {isExpandtaxes && (
                    <div className="taxes-content">
                      <div>
                        {cartItems.map((product, productIndex) => (
                          <div
                            key={productIndex}
                            className="product-item"
                          ></div>
                        ))}

                        <div className="aggregated-taxes">
                          {aggregatedTaxData.map((taxItem, index) => (
                            <div key={index} className="tax-container">
                              <p className="tax-label">{taxItem.description}</p>
                              <p className="tax-value">
                                ${((subtotal - couponDiscount) * taxItem.rate / 100).toFixed(2)}
                              </p>
                              <input
                                type="checkbox"
                                checked={checkedTaxes[taxItem.description]}
                                onChange={() => handleCheckboxChange(taxItem.description)}
                              />
                            </div>
                          ))}
                        </div>

                        {/* <div className="aggregated-taxes">
                          {aggregatedTaxData.map((taxItem, index) => (
                            <div key={index} className="tax-container">
                              <p className="tax-label">{taxItem.description}</p>
                              <p className="tax-value">
                                ${((subtotal - couponDiscount) * taxItem.rate / 100).toFixed(2)}
                              </p>
                              <input
                                type="checkbox"
                                checked={checkedTaxes[taxItem.description]}
                                onChange={() => handleCheckboxChange(taxItem.description)}
                              />
                            </div>
                          ))}
                        </div> */}


                        {/* <div className="aggregated-taxes">
                          {Object.keys(aggregatedTaxData).map(
                            (taxType, index) => (
                              <div key={index} className="tax-container">
                                <p className="tax-label">{taxType}</p>
                                <p className="tax-value">
                                  ${aggregatedTaxData[taxType].toFixed(2)}
                                </p>
                                <input
                                  type="checkbox"
                                  checked={checkedTaxes[taxType]}
                                  onChange={() => handleCheckboxChange(taxType)}
                                />
                              </div>
                            )
                          )}
                        </div> */}
                      </div>
                    </div>
                  )}

                  {/* ------------------------------------------------------------------- */}

                  <div className="cart-footer">
                    <div className="cart-summary">
                      {validatedPromoCode && (
                        <div>
                          <span>
                            Promo Code -{" "}
                            <span className="deal-name">
                              {validatedPromoCode}
                            </span>
                          </span>
                          <span className="color-text">
                            {" "}
                            - $
                            {couponDiscount
                              ? couponDiscount.toFixed(2)
                              : "0.00"}
                          </span>
                        </div>
                      )}
                      {loyaltyAmount > 0 && (
                        <div>
                          <span>Loyalty Points</span>
                          <span className="color-text">
                            - $
                            {loyaltyAmount ? loyaltyAmount.toFixed(2) : "0.00"}
                          </span>
                        </div>
                      )}

                      <div>
                        <span>Tax</span>
                        {/* <span>${tax.toFixed(2)}</span> */}
                        <span>${totalTaxAmount.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={`tab-pane ${selectedTab === "Delivery" ? "active" : ""}`}
          >
            <h2>Delivery</h2>
            <div className="quick-order">
              <Button className="quick-order-btn">Quick Order</Button>
            </div>
            <div className="cart-header d-flex justify-content-between">
              <div className="cart-head-left">
                <h3>Cart</h3>
                <span className="cart-head-items">
                  {cartItems.length} Items
                </span>
                <span className="cart-head-total">
                  Total - ${subtotal.toFixed(2)}
                </span>
              </div>
              <div className="cart-head-right">
                <button>Empty cart</button>
                <button>Park</button>
              </div>
            </div>
            <div className="cart-body">
              {cartItems.length === 0 ? (
                <div className="empty-cart">
                  <img src={EmptyCart} alt="Empty Cart" />
                </div>
              ) : (
                <>
                  <div className="cart-item-cont">
                    <div className="cart-item cart-item-head">
                      <span className="cart-item-name">Product Name</span>
                      <span className="cart-item-unitprice">Unit Price</span>
                      <span className="cart-item-qty">&nbsp;</span>
                      <span className="cart-item-totalprice">Total</span>
                      <span className="cart-item-action">&nbsp;</span>
                    </div>
                    {cartItems.map((item, index) => (
                      <div key={index} className="cart-item">
                        <span className="cart-item-name">{item.name}</span>
                        <span className="cart-item-unitprice">
                          ${item.product_price}
                        </span>
                        <span className="cart-item-qty quantity-control">
                          <Button onClick={() => handleDecrement(index)}>
                            -
                          </Button>
                          <span>{item.quantity}</span>
                          <Button onClick={() => handleIncrement(index)}>
                            +
                          </Button>
                        </span>
                        <span className="cart-item-totalprice">
                          ${(item.product_price * item.quantity).toFixed(2)}
                        </span>
                        <span className="cart-item-action">
                          <img
                            src={DeleteImg}
                            alt="Delete"
                            onClick={() => handleRemoveFromCart(index)}
                            style={{ cursor: "pointer", marginRight: "4px" }}
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="cart-sub-total">
                    <span>Subtotal</span>
                    <span>${subtotal.toFixed(2)}</span>
                  </div>

                  <div className="promocode">
                    <div>
                      <label>Enter Promo Code</label>
                      <span className="promo-input-wrap">
                        <input type="text" placeholder="" />
                        <Button type="submit">
                          <img src={ButtonTick} alt="" />
                        </Button>
                      </span>
                    </div>
                    <div>
                      <label>Enter Promo Code</label>
                      <span className="promo-input-wrap">
                        <input type="text" placeholder="" />
                        <Button type="submit">
                          <img src={ButtonTick} alt="" />
                        </Button>
                      </span>
                    </div>
                    <div>
                      <label>Enter Promo Code</label>
                      <span className="promo-input-wrap">
                        <input type="text" placeholder="" />
                        <Button type="submit">
                          <img src={ButtonCross} alt="" />
                        </Button>
                      </span>
                    </div>
                  </div>
                  <div className="cart-footer">
                    <div className="cart-summary">
                      <div>
                        <span>
                          Promo Code - <span className="deal-name">DEAL20</span>
                        </span>
                        <span className="color-text">
                          - ${discount.toFixed(2)}
                        </span>
                      </div>
                      <div>
                        <span>Loyalty Points</span>
                        <span className="color-text">
                          - ${discount.toFixed(2)}
                        </span>
                      </div>
                      <div>
                        <span>Taxkwjrff;alwkj;alkdjf;alkj</span>
                        {/* <span>${tax.toFixed(2)}</span> */}
                      </div>
                    </div>
                    <div className="cart-grand-total">
                      <span>Grand Total</span>
                      <span>${grandTotal.toFixed(2)}</span>
                    </div>
                    <div className="cart-header d-flex justify-content-between">
                      <div className="order-controls">
                        <Button
                          type={
                            selectedPaymentMethod === "Cash"
                              ? "primary"
                              : "default"
                          }
                          onClick={() => handleSelectPaymentMethod("Cash")}
                        >
                          <img src={IconCash} alt="" />
                          Cash
                        </Button>
                        <Button
                          type={
                            selectedPaymentMethod === "Card"
                              ? "primary"
                              : "default"
                          }
                          onClick={() => handleSelectPaymentMethod("Card")}
                        >
                          <img src={IconCard} alt="" />
                          Card
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <PromoCodePopup
        open={isPromoCodePopupVisible}
        onClose={handlePromoPopupClose}
        onApply={handleApplyPromoCode}
        setCouponCodes={handleSetCouponCodes}
        validatedPromoCode={validatedPromoCode}
      />
      <CashPaymentPopup
        total={grandTotal}
        isVisible={isPopupVisible}
        onClose={handlePopupClose}
        // {showLoginPopup && <LoginPopup onClose={handleLoginPopupClose} />}
        handlePlaceOrder={handlePlaceOrder}
      />
      <div className="cart-footer-cards d-flex justify-content-between">
        <div className="d-flex flex-column cart-place-order">
          <span className="">{cartItems.length} Items</span>
          <span className="cart-head-total">${grandTotal.toFixed(2)}</span>
        </div>
        <div className="order-controls">
          <Button
            className="cash-card-btn"
            type={selectedPaymentMethod === "Cash" ? "primary" : "default"}
            onClick={() => {
              handleSelectPaymentMethod("Cash");

              handleCashButtonClick();
            }}
          >
            <img src={IconCash} alt="" />
            Cash
          </Button>

          {showLoginPopup && (
            // <LoginPopup
            //   onClose={handleLoginPopupClose}
            //   selectedCustomer={selectedCustomer}
            //   indicator={setLoginCloseIndicator}
            // />
            <LoginPopup
              onClose={handleLoginPopupClose}
              selectedCustomer={selectedCustomer}
              indicator={setLoginCloseIndicator}
              onSuccessfulLogin={applyPriceChangeAfterLogin}
            />
          )}
          {/* {showLoginPopup && isLoginForCash && (
            // <LoginPopup
            //   onClose={handleLoginPopupClose}
            //   selectedCustomer={selectedCustomer}
            //   indicator={setLoginCloseIndicator}
            // />
            <LoginPopup
              onClose={handleLoginPopupClose}
              selectedCustomer={selectedCustomer}
              indicator={setLoginCloseIndicator}
              onSuccessfulLogin={applyPriceChangeAfterLogin}
            />
          )} */}

          {/* <Button
            className="cash-card-btn"
            type={selectedPaymentMethod === "Card" ? "primary" : "default"}
            onClick={() => handleSelectPaymentMethod("Card")}
          >
            <img src={IconCard} alt="" />
            Card
          </Button> */}
          {/* {!isguestCustomer && (
            <Button
              className="cash-card-btn"
              type={selectedPaymentMethod === "Credit" ? "primary" : "default"}
              onClick={() => handleCreditClick("Credit")}
            >
              <img src={IconCard} alt="" />
              Credit
            </Button>
          )} */}
          {!isguestCustomer && (
            <Button
              className="cash-card-btn"
              type={selectedPaymentMethod === "Credit" ? "primary" : "default"}
              onClick={() => handleCreditClick("Credit")}
            >
              <img src={IconCard} alt="" />
              Credit
            </Button>
          )}
        </div>
      </div>
      {authModalVisible && (
        <Modal
          visible={authModalVisible}
          onCancel={handleAuthCancel}
          footer={null}
          className="Auth-modal"
        >
          <div className="text-center">
            <h3>Authorization Required</h3>
            <p>
              The new price is outside the allowed range. Please authorize this
              change.
            </p>
          </div>
          <Input className="Auth-input" type="text" value={email} readOnly />
          <Input
            type="password"
            placeholder="Enter your password"
            className="Auth-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="authbtndiv">
            <button className="Authbtn" onClick={handleAuth}>
              Authorize
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Cart;

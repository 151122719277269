// import React, { useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import Layout from "./Layout";
// import { useOpenShift } from "./OpenShiftContext";
// import { getClosingShift, getCloseShiftdata } from "../modules/LandingPage";
// import { CartContext } from "../common/CartContext";
// import { result } from "lodash";
// import {notification} from 'antd';

// const CloseShiftScreen = () => {
//   const { openShiftData } = useOpenShift();
//   const navigate = useNavigate();
//   const [cashBalance, setCashBalance] = useState("");
//   const [digitalBalance, setDigitalBalance] = useState("0.00");
//   const [shiftData, setShiftData] = useState(null);
//   const { setCartItems } = useContext(CartContext);
  

//   useEffect(() => {
//     const fetchData = async () => {
//       const localDataOpenShift = localStorage.getItem("openingShiftResponse");

//       if (localDataOpenShift) {
//         const parsedData = JSON.parse(localDataOpenShift);
//         const posname = parsedData.message.pos_opening_shift.name;

//         console.log("posname -> ", parsedData.message.pos_opening_shift.name); // Check if posname is correct

//         try {
//           const result = await getCloseShiftdata(posname); // Pass posname to the function
//           console.log("API Response:", result);
//           setShiftData(result); // Store the API response in the state
//         } catch (error) {
//           console.error("Failed to fetch close shift data:", error);
//         }
//       } else {
//         console.log("No data found in localStorage");
//       }
//     };

//     fetchData();
//   }, []);

//   // useEffect(() => {
//   //   const fetchData = async () => {
//   //     const localDataOpenShift = localStorage.getItem("openingShiftResponse");

//   //     if (localDataOpenShift) {
//   //       const parsedData = JSON.parse(localDataOpenShift);
//   //       const posname = parsedData.message.pos_opening_shift.name;

//   //       console.log("posname -> ", parsedData.message.pos_opening_shift.name); // Check if posname is correct

//   //       try {
//   //         const result = await getCloseShiftdata(posname); // Pass posname to the function
//   //         console.log("API Response:", result);
//   //       } catch (error) {
//   //         console.error("Failed to fetch close shift data:", error);
//   //       }
//   //     } else {
//   //       console.log("No data found in localStorage");
//   //     }
//   //   };

//   //   fetchData();
//   // }, []);

//   useEffect(() => {
//     const balanceDetails =
//       openShiftData?.message?.pos_opening_shift?.balance_details || [];
//     const cashDetail = balanceDetails.find(
//       (detail) => detail.mode_of_payment === "Cash"
//     );
//     const digitalDetail = balanceDetails.find(
//       (detail) => detail.mode_of_payment !== "Cash"
//     );

//     // setCashBalance(cashDetail ? cashDetail.amount.toString() : "0.00");
//     // setDigitalBalance(digitalDetail ? digitalDetail.amount.toString() : "0.00");
//   }, [openShiftData]);

//   const handleLogout = async () => {
    
//     try {
//       if (!cashBalance || cashBalance === "0.00") {
//         notification.info({
//           message: "Cash Balance Required",
//           description: "Please enter the closing cash balance before proceeding.",
//         });
//         return;
//       }
//       const openingShiftResponseStr = localStorage.getItem(
//         "openingShiftResponse"
//       );

//       if (!openingShiftResponseStr) {
//         console.error("No opening shift response found in local storage.");
//         return;
//       }

//       const openingShiftResponse = JSON.parse(openingShiftResponseStr);

//       if (
//         !openingShiftResponse ||
//         !openingShiftResponse.message ||
//         !openingShiftResponse.message.pos_opening_shift ||
//         openingShiftResponse.message.pos_opening_shift.status !== "Open"
//       ) {
//         console.error(
//           "Invalid opening shift status or response:",
//           openingShiftResponse
//         );
//         alert(
//           "The selected POS Opening Shift is not open. Please select an open shift."
//         );
//         return;
//       }

//       const periodStartDate =
//         openingShiftResponse.message.pos_opening_shift.period_start_date || "";
//       const postingDate = new Date().toISOString();
//       const periodEndDate = new Date().toISOString();

//       const closingShiftData = {
//         closing_shift: {
//           period_start_date: formatDateString(periodStartDate),
//           posting_date: formatDateString(postingDate),
//           pos_profile: openingShiftResponse.message.pos_profile.name,
//           pos_opening_shift:
//             openingShiftResponse.message.pos_opening_shift.name,
//           doctype: "POS Closing Shift",
//           payment_reconciliation: [
//             {
//               mode_of_payment: "Cash",
//               opening_amount: parseFloat(cashBalance) || 0.0,
//               closing_amount: parseFloat(cashBalance) || 0.0,
//               expected_amount: parseFloat(cashBalance) || 0.0,
//               difference: 0.0,
//             },
//             // {
//             //   mode_of_payment: "Digital",
//             //   opening_amount: parseFloat(digitalBalance) || 0.0,
//             //   closing_amount: parseFloat(digitalBalance) || 0.0,
//             //   expected_amount: parseFloat(digitalBalance) || 0.0,
//             //   difference: 0.0,
//             // },
//           ],
//           period_end_date: formatDateString(periodEndDate),
//         },
//       };

//       const response = await getClosingShift(closingShiftData);

//       if (response) {
//         console.log("Shift closed successfully");
//         navigate("/closeshift");
//         setTimeout(() => {
//           navigate("/");
//           setCartItems([]);
//         }, 1000);
//         localStorage.clear();
//       } else {
//         console.error("Failed to close shift");
//       }
//     } catch (error) {
//       console.error("Error closing shift:", error);
//       alert("Error closing shift: " + error.message);
//     }
//   };

//   const formatDateString = (dateString) => {
//     const dateObj = new Date(dateString);
//     if (isNaN(dateObj.getTime())) {
//       throw new Error("Invalid date");
//     }
//     const formattedDate = dateObj.toISOString().slice(0, 23).replace("T", " ");
//     return formattedDate;
//   };

//   const total = shiftData
//     ? shiftData.message.opening_balance[0].amount + shiftData.message.Shift_Detail[0].cash_collected + shiftData.message.Shift_Detail[0].return_order_amount: 0;


//   return (
//     <Layout>
//       <div className="login-screen">
//         <h1>CLOSE SHIFT</h1>
//         <form className="login-form">
//           <div className="form-group">
//             <input
//               id="cash-balance"
//               type="text"
//               placeholder="Enter Closing Cash Balance"
//               value={cashBalance}
//               onChange={(e) => {
//                 const value = e.target.value;
//                 if (/^\d*\.?\d*$/.test(value) || value === "") {
//                   setCashBalance(value);
//                 }
//               }}
//             />
//             <span>
//               System Closing Cash Balance : ${total.toFixed(2)}
//               {/* {fetchData.message.opening_balance[0].amount + fetchData.message.Shift_Detail[0].cash_collected } */}
//               {/* {parseFloat(cashBalance || "0").toFixed(2)} */}
//             </span>
//           </div>
//           {/* <div className="form-group">
//             <input
//               id="digital-balance"
//               type="text"
//               placeholder="Enter Closing Digital Balance"
//               value={digitalBalance}
//               onChange={(e) => {
//                 const value = e.target.value;
//                 if (/^\d*\.?\d*$/.test(value)) {
//                   setDigitalBalance(value);
//                 }
//               }}
//             />
//             <span>
//               System Closing Digital Balance : $
//               {parseFloat(digitalBalance || "0").toFixed(2)}
//             </span>
//           </div> */}
//           <button
//             type="button"
//             onClick={handleLogout}
//             // disabled={!cashBalance || cashBalance === "0.00"}
//           >
//             Close Shift
//           </button>
//         </form>
//       </div>
//     </Layout>
//   );
// };

// export default CloseShiftScreen;


import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import { useOpenShift } from "./OpenShiftContext";
import { getClosingShift, getCloseShiftdata } from "../modules/LandingPage";
import { CartContext } from "../common/CartContext";
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const CloseShiftScreen = () => {
  const { t } = useTranslation();
  const { openShiftData } = useOpenShift();
  const navigate = useNavigate();
  const [cashBalance, setCashBalance] = useState("");
  const [digitalBalance, setDigitalBalance] = useState("0.00");
  const [shiftData, setShiftData] = useState(null);
  const { setCartItems } = useContext(CartContext);

  useEffect(() => {
    const fetchData = async () => {
      const localDataOpenShift = localStorage.getItem("openingShiftResponse");

      if (localDataOpenShift) {
        const parsedData = JSON.parse(localDataOpenShift);
        const posname = parsedData.message.pos_opening_shift.name;

        console.log("posname -> ", parsedData.message.pos_opening_shift.name);

        try {
          const result = await getCloseShiftdata(posname);
          console.log("API Response:", result);
          setShiftData(result);
        } catch (error) {
          console.error("Failed to fetch close shift data:", error);
        }
      } else {
        console.log(t('CloseShiftScreen.no_data_found'));
      }
    };

    fetchData();
  }, [t]);

  useEffect(() => {
    const balanceDetails =
      openShiftData?.message?.pos_opening_shift?.balance_details || [];
    const cashDetail = balanceDetails.find(
      (detail) => detail.mode_of_payment === "Cash"
    );
    const digitalDetail = balanceDetails.find(
      (detail) => detail.mode_of_payment !== "Cash"
    );

    setCashBalance(cashDetail ? cashDetail.amount.toString() : "0.00");
    setDigitalBalance(digitalDetail ? digitalDetail.amount.toString() : "0.00");
  }, [openShiftData]);

  const handleLogout = async () => {
    try {
      if (!cashBalance || cashBalance === "0.00") {
        notification.info({
          message: t('CloseShiftScreen.cash_balance_required'),
          description: t('CloseShiftScreen.cash_balance_description'),
        });
        return;
      }
      const openingShiftResponseStr = localStorage.getItem(
        "openingShiftResponse"
      );

      if (!openingShiftResponseStr) {
        console.error(t('CloseShiftScreen.no_data_found'));
        return;
      }

      const openingShiftResponse = JSON.parse(openingShiftResponseStr);

      if (
        !openingShiftResponse ||
        !openingShiftResponse.message ||
        !openingShiftResponse.message.pos_opening_shift ||
        openingShiftResponse.message.pos_opening_shift.status !== "Open"
      ) {
        console.error(
          "Invalid opening shift status or response:",
          openingShiftResponse
        );
        alert(
          t('CloseShiftScreen.error_closing_shift')
        );
        return;
      }

      const periodStartDate =
        openingShiftResponse.message.pos_opening_shift.period_start_date || "";
      const postingDate = new Date().toISOString();
      const periodEndDate = new Date().toISOString();

      const closingShiftData = {
        closing_shift: {
          period_start_date: formatDateString(periodStartDate),
          posting_date: formatDateString(postingDate),
          pos_profile: openingShiftResponse.message.pos_profile.name,
          pos_opening_shift:
            openingShiftResponse.message.pos_opening_shift.name,
          doctype: "POS Closing Shift",
          payment_reconciliation: [
            {
              mode_of_payment: "Cash",
              opening_amount: parseFloat(cashBalance) || 0.0,
              closing_amount: parseFloat(cashBalance) || 0.0,
              expected_amount: parseFloat(cashBalance) || 0.0,
              difference: 0.0,
            },
            // {
            //   mode_of_payment: "Digital",
            //   opening_amount: parseFloat(digitalBalance) || 0.0,
            //   closing_amount: parseFloat(digitalBalance) || 0.0,
            //   expected_amount: parseFloat(digitalBalance) || 0.0,
            //   difference: 0.0,
            // },
          ],
          period_end_date: formatDateString(periodEndDate),
        },
      };

      const response = await getClosingShift(closingShiftData);

      if (response) {
        console.log(t('CloseShiftScreen.logout_success'));
        navigate("/closeshift");
        setTimeout(() => {
          navigate("/");
          setCartItems([]);
        }, 1000);
        localStorage.clear();
      } else {
        console.error(t('CloseShiftScreen.error_closing_shift'));
      }
    } catch (error) {
      console.error(t('CloseShiftScreen.error_closing_shift'), error);
      alert(t('CloseShiftScreen.error_closing_shift') + ": " + error.message);
    }
  };

  const formatDateString = (dateString) => {
    const dateObj = new Date(dateString);
    if (isNaN(dateObj.getTime())) {
      throw new Error("Invalid date");
    }
    const formattedDate = dateObj.toISOString().slice(0, 23).replace("T", " ");
    return formattedDate;
  };

  const total = shiftData && shiftData.message.Shift_Detail.length > 0
  ? shiftData.message.Shift_Detail[0].cash_collected +
    shiftData.message.Shift_Detail[0].return_order_amount
  : 0;



  return (
    <Layout>
      <div className="login-screen">
        <h1>{t('CloseShiftScreen.close_shift_header')}</h1>
        <form className="login-form">
          <div className="form-group">
            <input
              id="cash-balance"
              type="text"
              placeholder={t('CloseShiftScreen.enter_cash_balance')}
              value={cashBalance}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value) || value === "") {
                  setCashBalance(value);
                }
              }}
            />
            <span>
              {t('CloseShiftScreen.system_closing_cash_balance')} : ${total.toFixed(2)}
            </span>
          </div>
          {/* <div className="form-group">
            <input
              id="digital-balance"
              type="text"
              placeholder="Enter Closing Digital Balance"
              value={digitalBalance}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*\.?\d*$/.test(value)) {
                  setDigitalBalance(value);
                }
              }}
            />
            <span>
              System Closing Digital Balance : $
              {parseFloat(digitalBalance || "0").toFixed(2)}
            </span>
          </div> */}
          <button
            type="button"
            onClick={handleLogout}
            // disabled={!cashBalance || cashBalance === "0.00"}
          >
            {t('CloseShiftScreen.close_shift_button')}
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default CloseShiftScreen;
